.section-one {
  /* height:95vh; */
  position: relative;
  /* margin-top:0; */
}

.banner-description {
  position: absolute;
  bottom: 0;
  height: 30%;
  width: 100%;
  display: flex;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 0 5% 5vh 5%;
}

.banner-description-title {
  font-family: 'Quicksand', sans-serif;
  color: white;
  font-size: 6vmin;
  font-weight: 700;
  margin-bottom: 1vh;
}

.banner-description-subtitle {
  font-family: 'Quicksand', sans-serif;
  color: white;
  font-size: 3vmin;
  font-weight: 800;
  margin-bottom: 2vh;
}

.see-how-it-works-button {
  text-decoration: none !important;
  background-color: #406dc9;
  color: white;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.9vmin;
  font-weight: 600;
  padding: 2vh 2vw 2vh 2vw;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.see-how-it-works-button:hover {
  color: white;
}

/* ************* */
/* beginning of section two (what we offer cards) */
/* ************* */

.section-two {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 15vh;
}

.what-we-offer {
  color: #406dc9;
  font-family: 'Quicksand', sans-serif;
  font-size: 5vmin;
  font-weight: 700;
  margin: 9vh 0 3vh 0;
}

.what-we-offer-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

/* *********** */
/* beginning of section three  nonprofit callout*/
/* ************ */

.nonprofit-callout-mobile-wrapper {
  display: none;
}

.top-cut-svg {
  width: 100%;
  display: none;
}

.nonprofit-callout-desktop-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nonprofit-callout {
  text-align: left;
}

.nonprofit-callout-title {
  font-family: 'Quicksand', sans-serif;
  font-size: 5vmin;
  font-weight: 700;
  color: #406dc9;
  margin-bottom: 4vh;
}

.nonprofit-callout-desc {
  font-family: 'Quicksand', sans-serif;
  font-size: 2.5vmin;
  font-weight: 600;
  color: black;
  margin-bottom: 2vw;
}

.nonprofit-callout-img {
  height: 100%;
  width: 50vw;
  margin-right: 5vw;
  padding: 0 0 0 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laptop1 {
  width: 55vw;
}

/* *********** */
/* beginning of section four (Donor Callout) */
/* ************ */

.donor-callout-mobile-wrapper {
  display: none;
}

.donor-callout-desktop-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.donor-callout {
  text-align: left;
  box-sizing: border-box;
}

.donor-callout-title {
  font-family: 'Quicksand', sans-serif;
  font-size: 5vmin;
  font-weight: 700;
  color: #406dc9;
  margin-bottom: 4vh;
}

.donor-callout-desc {
  font-family: 'Quicksand', sans-serif;
  font-size: 2.5vmin;
  font-weight: 600;
  color: black;
  margin-bottom: 2vw;
}

.donor-callout-img {
  height: 100%;
  width: 50vw;
  margin-left: 5vw;
  padding: 5vh 5vw 5vh 5vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phones {
  width: 32vw;
}

/* ************ */
/* Beginning of section five (business callout) */
/* ************ */

.business-callout-desktop-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.business-callout-mobile-wrapper {
  display: none;
}

.business-callout {
  text-align: left;
  margin: 0 0 0 5vw;
}

.business-callout-title {
  font-family: 'Quicksand', sans-serif;
  font-size: 5vmin;
  font-weight: 700;
  color: #406dc9;
  margin-bottom: 4vh;
}

.business-callout-desc {
  margin-bottom: 2vw;
  font-family: 'Quicksand', sans-serif;
  font-size: 2.5vmin;
  font-weight: 600;
  color: black;
}

.business-callout-img {
  height: 100%;
  width: 50vw;
  margin-right: 5vw;
  padding: 0 0 0 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laptop2 {
  width: 55vw;
}

.bottom-cut-svg {
  width: 100%;
  display: none;
}

/* ************ */
/* beginning of section six (vission/mission carousel) */
/* ************ */

.section-six {
  margin-bottom: 20vh;
}

/* ************ */
/* beginning of section seven (Partner and invest) */
/* ************ */
.section-seven {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 25%;
}

.partner-and-invest-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 5% 0 5%;
}

.hands-in-image {
  width: 100%;
  background-position: center;
  background-size: cover;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.partner-and-invest-text-wrapper {
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 0 2vw 0 5vw;
  font-family: 'Quicksand', sans-serif;
}

.partner-and-invest-title {
  color: #406dc9;
  font-size: 2.8vmin;
  font-weight: 700;
  margin-bottom: 5vh;
}

.partner-and-invest-text {
  text-align: left;
  color: #6c6c6c;
  font-size: 2.2vmin;
  font-weight: 500;
  margin-bottom: 4vh;
}

#root>div>div.section-seven>div>div>div.contact-us-wrapper>a {
  display: inline-block;
  margin: auto;
  padding: 1.2vh 1.8vw 1.2vh 1.8vw;
}

.app-mobile-only {
  display: none;
}

.get-started-now,
.get-started-now:hover {
  align-self: center;
  padding: 1vw 2vw;
  background-color: #406dc9;
  color: white !important;
  border-radius: 4vw;
  font-size: 1rem;
  font-size: clamp(1rem, 2.2vmin, 10rem);
  font-weight: 600;
  text-decoration: none;
}

.go-to-desktop {
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

#hide-on-desktop {
  display: none;
}

#hide-on-mobile {
  display: block;
}

.mobile-dashboardd {
  background-color: rgb(72, 116, 205);
  min-height: auto;
}

.mobile-dashboardd .dashboard-sec-1 {
  padding: 30px 20px 10px 20px;
  background: linear-gradient(90deg, rgb(140, 82, 255) 0%, rgb(92, 225, 230) 100%) 0px 0px;
  border-radius: 0px 0px 40px 40px;
  position: relative;
  overflow: hidden;
}

.mobile-dashboardd .dashboard-sec-1 .top {}

.mobile-dashboardd .dashboard-sec-1 .top .dashboard {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mobile-dashboardd .dashboard-sec-1 .top .dashboard img {
  width: 20px;
}

.mobile-dashboardd .dashboard-sec-1 .top .dashboard span {
  font-weight: 500;
  font-style: normal;
  color: rgb(250, 250, 250);
  text-decoration: none;
  font-size: 16px;
}

.mobile-dashboardd .dashboard-sec-1 .top .title h1 {
  font-weight: 700;
  font-style: normal;
  color: rgb(250, 250, 250);
  text-decoration: none;
  font-size: 26px;
  text-transform: capitalize;
}

.mobile-dashboardd .dashboard-sec-1 .bottom {}

.mobile-dashboardd .dashboard-sec-1 .bottom .need-help {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  margin-top: 10px;
}

.mobile-dashboardd .dashboard-sec-1 .bottom .need-help a {
  font-weight: 500;
  font-style: normal;
  color: rgb(250, 250, 250);
  text-decoration: none;
  font-size: 16px;
  text-decoration: underline;
}

.mobile-dashboardd .dashboard-sec-1 .bottom .need-help span {
  font-weight: 800;
  font-style: normal;
  color: rgb(0 219 234);
  background-color: rgb(250, 250, 250);
  text-decoration: none;
  font-size: 14px;
  border-radius: 25px;
  padding: 0px 7px;
  line-height: 16px;
}

.mobile-dashboardd .dashboard-sec-1 .bottom .need-help {}

.mobile-dashboardd .dashboard-sec-1 .cricle-1 {
  position: absolute;
  background: #fff;
  opacity: 0.1;
  width: 130px;
  height: 130px;
  top: -50px;
  left: -15px;
  border-radius: 100px;
}

.mobile-dashboardd .dashboard-sec-1 .cricle-2 {
  position: absolute;
  background: #fff;
  opacity: 0.1;
  width: 75px;
  height: 75px;
  top: 40%;
  left: 25%;
  border-radius: 100px;
  transform: translate(-50%, -40%);
}

.dsahboard-sec-3 .sec-2-wrap {
  /* display: flex; */
}

.dsahboard-sec-3 .sec-2-wrap .wrap {
  display: flex;
  gap: 9px;
}

.dsahboard-sec-3 .sec-2-wrap .col-4 .div {
  background-color: #fff;
  padding: 10px;
  flex: 1;
  text-align: center;
  height: 100%;
  border-radius: 12px;
}

.dsahboard-sec-3 .sec-2-wrap .col-4 .div a {
  text-decoration: none;
}

.dsahboard-sec-3 .sec-2-wrap .col-4 .div img {
  width: 20px;
}

.dsahboard-sec-3 .sec-2-wrap .col-4 .div p {
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 9px;
  line-height: 12px;
  margin-top: 5px !important;
}

.donation-data-not-fount {}

.donation-data-not-fount .donation-data-not-fount-wrap {
  height: calc(100vh - 72.8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.donation-data-not-fount .donation-data-not-fount-wrap .boxxx {
  width: 500px;
  position: relative;
  border: 1px solid #e4e1e4;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px 60px 20px;
}

.donation-data-not-fount .donation-data-not-fount-wrap .boxxx span {
  position: absolute;
  right: 10px;
  top: 0;
  padding: 10px;
  font-size: 22px;
  color: #000;
  font-weight: 600;
}

.donation-data-not-fount .donation-data-not-fount-wrap .boxxx p {
  font-weight: 600;
  font-size: x-large;
  text-align: center;
  font-size: 30px;
  color: #000;
  max-width: 330px;
}

.donation-data-not-fount .donation-data-not-fount-wrap .boxxx button {
  font-size: larger;
  font-weight: 600;
  padding: 6px 26px;
  border: 1px solid #406dc9;
  border-radius: 6px;
  color: white;
  background: #406dc9;
  border-color: #406dc9;
  margin-top: 5px;
  width: fit-content;
}

.donation-type {
  margin-top: 8px;
}

.donation-type ul {
  margin: 0;
  padding: 0;
  display: flex;
  border-radius: 4px;
  background-color: #f1eff1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.donation-type ul li {
  list-style: none;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 7px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.donation-type ul li:nth-child(1) {
  flex: 1;
}

.donation-type ul li:nth-child(2) {
  flex: 1;
}

.donation-type ul li.active {
  background-color: #e7efff;
  color: #000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.donation-type ul li:hover {
  background-color: #e7efff;
  color: #000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.donation-type ul li.active {}

.ach-payment-modal {}
.ach-payment-modal.opacity{
  opacity: 0;
}

.ach-payment-modal .modal-dialog {
  transform: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

.ach-payment-modal .modal-dialog {
  width: 700px;
  min-width: 700px;
  position: relative;
}

.ach-payment-modal .close{
  padding: 10px 10px 10px 10px;
  font-size: 22px;
  color: #000;
  font-weight: 600;
  margin: 10px 10px 0px 0px;
  cursor: pointer;
}

.c-modal {}

.c-modal .modal-dialog {
  transform: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

.c-modal .modal-content{
  border-radius: 12px;
}
.c-modal .modal-dialog {
  width: 500px;
  min-width: 500px;
  position: relative;
}

.c-modal .modal-body{
padding: 30px;
border-radius: 12px;
}
.c-modal .close{
  position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;
    font-size: 22px;
    color: #000;
    font-weight: 600;
    cursor: pointer;
}

.c-modal .c-desc{
  font-weight: 600;
  text-align: center;
  font-size: 26px;
  line-height: 34px;
  color: #000;
  margin-top: 20px;
}
.c-modal .c-buttons{
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}
.c-modal .c-buttons button{
  font-size: larger;
    font-weight: 600;
    padding: 6px 26px;
    border: 1px solid #406dc9;
    border-radius: 6px;
    color: white;
    background: #406dc9;
    border-color: #406dc9;
    margin-top: 5px;
    width: fit-content;
}

.a-s-b {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ach-submit-button {
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  font-size: larger;
  font-weight: 600;
  padding: 6px 26px;
  border: 1px solid var(--neddie-blue);
  border-radius: 50vw;
  color: white;
  background: var(--neddie-blue);
  border-color: var(--neddie-blue);
  margin-top: 5px;
  width: fit-content;
}

.modal-backdrop.show {
  opacity: 1 !important;
}

.modal-backdrop {
  background-color: transparent !important;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(10px);
  background-color: transparent !important;
}

.requires-action-popups .modal-content {
  padding: 50px 20px 20px 20px;
  border-radius: 20px;
  position: relative;
}

.requires-action-popups .cross {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 18px;
  font-weight: 700;
  padding: 5px;
  cursor: pointer;
}

.requires-action-popups h1 {
  font-size: 32px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}

.requires-action-popups p {
  font-size: 21px;
  text-align: center;
  font-weight: 500;
}

.requires-action-popups .buttonn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.requires-action-popups .buttonn button {
  border-radius: 8px;
  padding: 5px 50px;
  border: 2px solid var(--neddie-blue);
  background: var(--neddie-blue);
  color: #fff;
  font-size: 24px;
}

.requires-action-popups .buttonns {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 20px;
}

.requires-action-popups .buttonns a {
  text-decoration: none;
  border-radius: 8px;
  padding: 5px 30px;
  border: 2px solid var(--neddie-blue);
  background: var(--neddie-blue);
  color: #fff;
  font-size: 24px;
}

@media only screen and (max-width: 520px) {
  .donation-data-not-fount {}

  .donation-data-not-fount .donation-data-not-fount-wrap {
    height: calc(100vh - 72.8px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .donation-data-not-fount .donation-data-not-fount-wrap .boxxx {
    width: 90%;
    position: relative;
    border: 1px solid #e4e1e4;
    border-radius: 12px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px 40px 20px;
    margin-inline: auto;
  }

  .donation-data-not-fount .donation-data-not-fount-wrap .boxxx span {
    position: absolute;
    right: 10px;
    top: 0;
    padding: 10px;
    font-size: 22px;
    color: #000;
    font-weight: 600;
  }

  .donation-data-not-fount .donation-data-not-fount-wrap .boxxx p {
    font-weight: 600;
    font-size: x-large;
    text-align: left;
    font-size: 22px;
    color: #000;
  }

  .donation-data-not-fount .donation-data-not-fount-wrap .boxxx button {
    font-size: 13px;
    font-weight: 600;
    padding: 6px 26px;
    border: 1px solid #406dc9;
    border-radius: 6px;
    color: white;
    background: #406dc9;
    border-color: #406dc9;
    margin-top: 5px;
    width: fit-content;
  }
}

/* this will encompass 99% of phones and some tablets. */
@media only screen and (max-width: 768px) {

  .ach-payment-modal .modal-dialog {
    width: 100%;
    min-width: 100%;
    margin: 0;
  }

  .ach-payment-modal .modal-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ach-payment-modal .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .ach-payment-modal {
    padding-left: 0px !important;
  }

  .ach-payment-modal .close {
    padding: 10px;
    font-size: 22px;
    color: #000;
    font-weight: 600;
    margin: 0px 0px 0 0;
    cursor: pointer;
    position: absolute;
    top: 15px !important;
    right: 15px;
}

  .ach-payment-modal .modal-body form {
    width: 100%;
  }

  #hide-on-desktop {
    display: block;
  }

  #hide-on-mobile {
    display: none;
  }

  /* ************* */
  /* beginning of section one (banner) */
  /* ************* */

  .whats-new-popups .dashboard-info-h2 {
    font-size: 24px;
  }

  .dashboard-update-title {
    font-size: 20px;
  }

  .dashboard-update-description {
    font-size: 16px;
    line-height: 20px;
  }

  .dashboard-update-title {
    line-height: 20px;
    margin-bottom: 10px;
  }

  .dashboard-update-boxes-container {
    height: 330px;
  }

  .manage-wish-popups .cancle-popup {
    position: absolute;
    top: 5px;
    right: 7px;
    font-size: 34px;
    padding: 10px;
    line-height: 16px;
    cursor: pointer;
  }

  .section-one {
    margin-top: 75px;
    height: 55vh;
  }

  .app-desktop-only {
    display: none;
  }

  .app-mobile-only {
    display: block;
  }

  .banner-description {
    height: auto;
    padding-bottom: 0;
    position: absolute;
    /* font-size: 2rem; */
  }

  .banner-description-title {
    font-size: 6vw;
  }

  .banner-description-subtitle {
    font-size: 4.5vw;
  }

  .see-how-it-works-button {
    display: none;
    font-size: 4vmin !important;
    padding: 2vmin 8vmin !important;
  }

  /* ************* */
  /* beginning of section two (what we offer cards) */
  /* ************* */

  .section-two {
    margin-bottom: 0%;
  }

  .what-we-offer {
    font-size: 2rem;
    margin: 10% 0 2% 0;
  }

  .what-we-offer-row {
    flex-direction: column;
  }

  /* *********** */
  /* beginning of section three  nonprofit callout*/
  /* ************ */

  .top-cut-svg {
    width: 100%;
    display: block;
  }

  .nonprofit-callout-mobile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f6f8;
  }

  .get-started-now {
    padding-left: 6vw;
    padding-right: 6vw;
    margin-bottom: 8vw;
  }

  .nonprofit-callout-desktop-wrapper {
    display: none;
  }

  .nonprofit-callout {
    margin: 0 0 0 0;
  }

  .nonprofit-callout-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: #406dc9;
    margin-bottom: 6%;
  }

  .nonprofit-callout-desc {
    font-size: 1rem;
    font-weight: 600;
    color: #173269;
    text-align: left;
    padding: 0 15% 0 15%;
  }

  .nonprofit-callout-img {
    height: 40%;
    width: 100%;
    margin-right: 0;
    padding: 0 0 0 0;
  }

  .laptop1 {
    width: 80%;
  }

  /* *********** */
  /* beginning of section four (Donor Callout) */
  /* ************ */

  .donor-callout-desktop-wrapper {
    display: none;
  }

  .donor-callout-mobile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f6f8;
  }

  .donor-callout {
    text-align: center;
    margin: 0 0 0 0;
  }

  .donor-callout-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: #406dc9;
    margin-bottom: 10%;
  }

  .donor-callout-desc {
    font-size: 1rem;
    font-weight: 600;
    color: #173269;
    text-align: left;
    padding: 3% 15% 10% 15%;
  }

  .donor-callout-img {
    height: 40%;
    width: 100%;
    margin: 0;
    padding: 0 0 0 0;
  }

  .phones {
    width: 60%;
  }

  /* ************ */
  /* Beginning of section five (business callout) */
  /* ************ */

  .business-callout-desktop-wrapper {
    display: none;
  }

  .business-callout-mobile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f6f8;
  }

  .business-callout {
    text-align: left;
    margin: 0 0 0 5vw;
  }

  .business-callout-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: #406dc9;
    margin: 2% 0 10% 0;
  }

  .business-callout-desc {
    font-size: 1rem;
    font-weight: 600;
    color: #173269;
    text-align: left;
    padding: 3% 15% 10% 15%;
  }

  .business-callout-img {
    height: 40%;
    width: 100%;
    margin: 0;
    padding: 0 0 0 0;
  }

  .laptop2 {
    width: 80%;
  }

  .bottom-cut-svg {
    width: 100%;
    display: block;
  }

  /* ************ */
  /* Beginning of section six vision carousel */
  /* ************ */
  .section-six {
    display: none;
  }

  /* ************ */
  /* beginning of section seven (Partner and invest) */
  /* ************ */

  .section-seven {
    margin: 0% 0 10% 0;
  }

  .partner-and-invest-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0 15%;
  }

  .hands-in-image {
    display: none;
  }

  .partner-and-invest-text-wrapper {
    padding: 0;
  }

  .partner-and-invest-title {
    color: #406dc9;
    font-size: 1.6rem;
    font-weight: 600;
    margin: 1.3rem 0;
  }

  .partner-and-invest-text {
    text-align: left;
    color: #6c6c6c;
    font-size: 1rem;
    color: #173269;
    font-weight: 600;
    margin-bottom: 4vh;
  }

  #root>div>div.section-seven>div>div>div.contact-us-wrapper>a {
    display: inline-block;
    margin: auto;
    font-size: 3vmin;
    padding: 1.2% 4% 1.2% 4%;
  }

  .mobile-dashboard {
    background-color: rgb(72, 116, 205);
    min-height: auto;
  }

  .mobile-dashboard .dashboard-sec-1 {
    padding: 30px 20px 10px 20px;
    background: linear-gradient(90deg, rgb(140, 82, 255) 0%, rgb(92, 225, 230) 100%) 0px 0px;
    border-radius: 0px 0px 40px 40px;
    position: relative;
    overflow: hidden;
  }

  .mobile-dashboard .dashboard-sec-1 .top {}

  .mobile-dashboard .dashboard-sec-1 .top .dashboard {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .mobile-dashboard .dashboard-sec-1 .top .dashboard img {
    width: 20px;
  }

  .mobile-dashboard .dashboard-sec-1 .top .dashboard span {
    font-weight: 500;
    font-style: normal;
    color: rgb(250, 250, 250);
    text-decoration: none;
    font-size: 16px;
  }

  .mobile-dashboard .dashboard-sec-1 .top .title h1 {
    font-weight: 700;
    font-style: normal;
    color: rgb(250, 250, 250);
    text-decoration: none;
    font-size: 26px;
    text-transform: capitalize;
  }

  .mobile-dashboard .dashboard-sec-1 .bottom {}

  .mobile-dashboard .dashboard-sec-1 .bottom .need-help {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
    margin-top: 10px;
  }

  .mobile-dashboard .dashboard-sec-1 .bottom .need-help a {
    font-weight: 500;
    font-style: normal;
    color: rgb(250, 250, 250);
    text-decoration: none;
    font-size: 16px;
    text-decoration: underline;
  }

  .mobile-dashboard .dashboard-sec-1 .bottom .need-help span {
    font-weight: 800;
    font-style: normal;
    color: rgb(0 219 234);
    background-color: rgb(250, 250, 250);
    text-decoration: none;
    font-size: 14px;
    border-radius: 25px;
    padding: 0px 7px;
    line-height: 16px;
  }

  .mobile-dashboard .dashboard-sec-1 .bottom .need-help {}

  .mobile-dashboard .dashboard-sec-1 .cricle-1 {
    position: absolute;
    background: #fff;
    opacity: 0.1;
    width: 130px;
    height: 130px;
    top: -50px;
    left: -15px;
    border-radius: 100px;
  }

  .mobile-dashboard .dashboard-sec-1 .cricle-2 {
    position: absolute;
    background: #fff;
    opacity: 0.1;
    width: 75px;
    height: 75px;
    top: 40%;
    left: 25%;
    border-radius: 100px;
    transform: translate(-50%, -40%);
  }

  .mobile-dashboard .dashboard-sec-2 {
    margin: 20px 26px;
  }

  .mobile-dashboard .dashboard-sec-2 p {
    background-color: rgb(241 240 241);
    font-weight: 700;
    font-style: normal;
    color: rgb(68, 68, 68);
    text-decoration: none;
    font-size: 15px;
    padding: 15px 40px;
    border-radius: 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
  }

  .mobile-dashboard .dashboard-sec-2 p span {
    text-decoration: underline;
  }

  .mobile-dashboard .dashboard-sec-2 .cricle-1 {

    position: absolute;
    width: 45px;
    height: 45px;
    left: -15px;
    bottom: -15px;
    background-color: rgb(72, 116, 205);
    border-radius: 100px;
    opacity: 0.4;
  }

  .mobile-dashboard .dashboard-sec-2 .cricle-2 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    bottom: 6px;
    background-color: rgb(72, 116, 205);
    border-radius: 100px;
    opacity: 0.4;
  }

  .mobile-dashboard .dashboard-sec-2 .cricle-3 {
    position: absolute;
    width: 40px;
    height: 40px;
    right: -10px;
    top: -15px;
    background-color: rgb(72, 116, 205);
    border-radius: 100px;
    opacity: 0.4;
  }

  .mobile-dashboard .dashboard-sec-2 .cricle-4 {
    position: absolute;
    width: 40px;
    height: 40px;
    right: -20px;
    top: 4px;
    background-color: rgb(72, 116, 205);
    border-radius: 100px;
    opacity: 0.4;
  }

  .mobile-dashboard .dashboard-sec-2 .cricle-5 {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 8px;
    top: 10px;
    background-color: rgb(72, 116, 205);
    border-radius: 100px;
    opacity: 0.4;
  }

  .dsahboard-sec-3 {
    padding-inline: 40px;
    padding-bottom: 10px;
    height: 400px;
    display: flex;
    align-items: center;
  }

  .dsahboard-sec-3 .sec-2-wrap {
    /* display: flex; */
  }

  .dsahboard-sec-3 .sec-2-wrap .wrap {
    display: flex;
    gap: 9px;
  }

  .dsahboard-sec-3 .sec-2-wrap .col-4 div {
    background-color: #fff;
    padding: 10px;
    flex: 1;
    text-align: center;
    height: 100%;
    border-radius: 12px;
  }

  .dsahboard-sec-3 .sec-2-wrap .col-4 div img {
    width: 20px;
  }

  .dsahboard-sec-3 .sec-2-wrap .col-4 div p {
    font-weight: 700;
    font-style: normal;
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 9px;
    line-height: 12px;
    margin-top: 5px !important;
  }

  .donation-type ul li:nth-child(2) {
    flex: 2;
  }
}