.manage-wishlist-h2 {
  font-size: 2vw;
  color: #173269;
  font-weight: 700;
  padding-left: 1vw;
}
.manage-wishlist-h5 {
  padding-top: 1vw;
  padding-left: 1vw;
  font-size: 1.2vw;
  font-size: clamp(5px, 1.2vw, 45px);
  color: #646464;
  font-weight: 600;
}
.manage-wishlist-info-container {
  width: 100%;
  height: 40vw;
  background-color: #ffff;
  border-radius: 1vw;
  padding: 3vw;
  margin-top: 1.5vw;
  font-weight: 600;
}
.manage-wishlist-info-container > h4 {
  font-size: 1.3vw;
  font-size: clamp(5px, 1.3vw, 45px);
  color: #262d33;
  font-weight: 700;
}
.manage-wishlist-info-container > p {
  font-size: 1vw;
  font-size: clamp(5px, 1vw, 45px);
  color: #b1b1b1;
  font-weight: 600;
}
.manage-wishlist-info-container > h3 {
  padding-top: 1vw;
  font-size: 1.2vw;
  font-size: clamp(5px, 1.2vw, 45px);
  color: #4592ff;
  font-weight: 600;
  cursor: pointer;
}
.manage-wishlist-info-container > h3:hover {
  color: #4188eb;
}
.manage-wishlist-addlist-form-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
}
.manage-wishlist-addlist-form {
  display: flex;
  flex-direction: column;
  padding-top: 1.5vw;
}
.manage-wishlist-addlist-form input {
  border: 0.75px solid #173269;
  border-radius: 0.25vw;
  width: 25vw;
  height: 2.5vw;
  color: #6a6969;
  font-size: 0.75vw;
  font-size: clamp(2px, 0.75vw, 40px);
  padding-left: 0.5vw;
}
.manage-wishlist-addlist-form select {
  border: 0.75px solid #173269;
  border-radius: 0.25vw;
  width: 25vw;
  height: 2.5vw;
  color: #6a6969;
  font-size: 0.75vw;
  font-size: clamp(2px, 0.75vw, 40px);
  padding-left: 0.5vw;
}
.manage-wishlist-addlist-form label {
  color: #4b5157;
  font-size: 0.9vw;
  font-size: clamp(2px, 0.9vw, 40px);
  padding-top: 1vw;
}

.manage-wishlist-addlist-form-container h4,
h2 {
  position: relative;
  font-size: 1.3vw;
  font-size: clamp(5px, 1.3vw, 45px);
  color: #4b5157;
  font-weight: 700;
  padding-top: 2vw;
  padding-bottom: 0.25vw;
}
.manage-wishlist-addlist-form-container h4::before,
h2::before {
  position: absolute;
  content: '';
  bottom: 0;
  width: 1.5vw;
  left: 6%;
  border-bottom: 0.15vw solid #83bedf;
}
.manage-wishlist-addlist-placeholder {
  color: #6a6969;
  font-size: 0.75vw;
  font-size: clamp(2px, 0.75vw, 40px);
}
.manage-wishlist-addlist-form option {
  color: #262d33;
  font-size: 0.6vw;
  font-size: clamp(2px, 0.6vw, 40px);
}
.manage-wishlist-info-icon-container {
  position: relative;
  z-index: 100;
}
.manage-wishlist-info-icon {
  width: 1.25vw;
  height: 1.25vw;
  z-index: 100;
}
.manage-wishlist-info-icon-container .manage-wishlist-tooltiptext {
  visibility: hidden;
  width: 10vw;
  height: 10vw;
  background-color: #ffff;
  color: #406dc9;
  text-align: left;
  border-radius: 1vw;
  padding: 0.25vw;
  border: 0.1vw solid #c6c6c6;
  bottom: -1vw;
  position: absolute;
  z-index: 100;
}
.manage-wishlist-info-icon-container .manage-wishlist-small-tooltiptext {
  visibility: hidden;
  width: 10vw;
  height: 7vw;
  background-color: #ffff;
  color: #406dc9;
  text-align: left;
  border-radius: 1vw;
  padding: 0.5vw;
  border: 0.1vw solid #c6c6c6;
  bottom: -1vw;
  position: absolute;
  z-index: 100;
}
.manage-wishlist-info-icon-container
  .manage-wishlist-tooltiptext
  .manage-wishlist-tooltiptext-h3 {
  font-size: 1vw;
  font-size: clamp(3px, 0.75vw, 50px);
  font-weight: 600;
  padding-bottom: 0.15vw;
}
.manage-wishlist-info-icon-container
  .manage-wishlist-tooltiptext
  .manage-wishlist-tooltiptext-p {
  font-size: 0.75vw;
  font-size: clamp(3px, 0.5vw, 50px);
}
.manage-wishlist-info-icon-container
  .manage-wishlist-small-tooltiptext
  .manage-wishlist-tooltiptext-h3 {
  font-size: 1vw;
  font-size: clamp(3px, 0.75vw, 50px);
  font-weight: 600;
  padding-bottom: 0.15vw;
}
.manage-wishlist-info-icon-container
  .manage-wishlist-small-tooltiptext
  .manage-wishlist-tooltiptext-p {
  font-size: 0.75vw;
  font-size: clamp(3px, 0.5vw, 50px);
}
.manage-wishlist-info-icon-container:hover .manage-wishlist-tooltiptext {
  visibility: visible;
}
.manage-wishlist-info-icon-container:hover .manage-wishlist-small-tooltiptext {
  visibility: visible;
}
.manage-wishlist-addlist-input-row {
  display: flex;
  flex-direction: row;
}

.manage-wishlist-cancel-btn {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 0.9vw;
  font-size: clamp(2px, 0.9vw, 50px);
  color: #406dc9;
  width: 8vw;
  height: 2vw;
}
.manage-wishlist-cancel-btn:hover {
  color: #3b66bd;
}
.manage-wishlist-cancel-btn:active {
  outline: none;
}
.manage-wishlist-spinner {
  display: flex;
  align-self: center;
  margin-bottom: 20vw;
}
.saving-wishlist-spinner-container {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
  width: 100%;
  height: 100%;

  /* background-color: rgba(0, 0, 0, 0.25); */
}
.saving-wishlist-spinner-content {
  background-color: #f5f4f4;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  left: 40%;
  width: 30vw;
  height: 10vw;
  padding: 20px;
  border-radius: 5px;
  border: 0.1vw solid #c6c6c6;
  font-size: 1vw;
  font-size: clamp(5px, 1vw, 50px);
  color: #406dc9;
}

.manage-wish-popups .modal-content{
  border-radius: 20px;
}
.manage-wish-popups .manage-marketplace-info-container{
  width: 100%;
  height: auto !important;
  background-color: #ffff;
  border-radius: 1vw;
  padding: 3vw;
  margin-top: 1.5vw;
  font-weight: 600;
}

.manage-wish-popups .modal-dialog {
    max-width: 800px
}

.manage-wish-popups .modal-content .manage-wish-wrap{
  padding: 30px;
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 27px;
  position: relative;
}

.manage-wish-popups .manage-marketplace-h2 {
  font-size: 31px;
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.manage-wish-popups .manage-marketplace-addlist-form label {
font-size: 26px;
padding-top: 1vw;
font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.manage-wish-popups  .manage-marketplace-addlist-form input {
border: 1px solid #000;
border-radius: 0.25vw;
width: 80%;
height: auto;
color: #6a6969;
background-color: rgb(241, 240, 241);
padding-left: 0.7vw 0.5vw;
font-weight: 500;
  font-style: normal;
  color: #000;
  text-decoration: none;
  font-size: 26px;
  margin-bottom: 20px;
}


.manage-wish-popups  .manage-marketplace-addlist-form input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
color: #B2AFB2;
font-size: 26px;
}

.manage-wish-popups  .manage-marketplace-addlist-form input:-moz-placeholder,
textarea:-moz-placeholder {
color: #B2AFB2;
font-size: 26px;
}

.manage-wish-popups .neddie-button{
transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
font-size: 26px;
font-weight: 600;
padding: 4px 36px;
border: 1px solid var(--neddie-blue);
border-radius: 50vw;
color: white;
background: var(--neddie-blue);
border-color: var(--neddie-blue);
margin-top: 5px;
width: fit-content;
}

.manage-wish-popups .cancle-popup{
position: absolute;
top: 17px;
right: 25px;
font-size: 34px;
padding: 10px;
line-height: 16px;
cursor: pointer;
}

.modal-backdrop{
background-color: #fff;
}

@media only screen and (max-width: 768px) {
/* ************* */
/* beginning of section one (banner) */
/* ************* */

.manage-wish-popups .cancle-popup {
  position: absolute;
  top: 5px;
  right: 7px;
  font-size: 34px;
  padding: 10px;
  line-height: 16px;
  cursor: pointer;
}

.manage-wish-popups .manage-marketplace-h2 {
font-size: 24px;
font-weight: 700;
font-style: normal;
color: rgb(0, 0, 0);
text-decoration: none;
padding: 0;
margin: 0;
}

.manage-wish-popups .modal-content .manage-wish-wrap {
font-size: 20px;
}

.manage-wish-popups .manage-marketplace-addlist-form label {
font-size: 20px;
}

.manage-wish-popups .manage-marketplace-addlist-form input {
width: 100%;
}

.manage-wish-popups .manage-marketplace-addlist-form input {
padding: 7px 10px;
font-size: 16px;
border-radius: 6px;
margin-bottom: 10px;
}

.manage-wish-popups .manage-marketplace-addlist-form input::placeholder {
font-size: 16px;
}

.manage-wish-popups .neddie-button {
transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
font-size: 20px;
font-weight: 600;
padding: 4px 36px;
border: 1px solid var(--neddie-blue);
border-radius: 50vw;
color: white;
background: var(--neddie-blue);
border-color: var(--neddie-blue);
margin-top: 5px;
width: fit-content;
}

.manage-wish-popups .modal-content {
border-radius: 16px;
}

}