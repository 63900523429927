#searchbarOuter {
  /* margin-top: 40px; */
  margin-left: 0px;
  width: 100%;
}

.dropdown:hover {
  display: block;
}
/* @media only screen and (max-width: 1600px) {
  #searchbarOuter {
    width: 47%;
  }
}
@media only screen and (max-width: 1550px) {
  #searchbarOuter {
    width: 44%;
  }
}
@media only screen and (max-width: 1500px) {
  #searchbarOuter {
    width: 44%;
  }
}
@media only screen and (max-width: 1450px) {
  #searchbarOuter {
    width: 42%;
  }
}
@media only screen and (max-width: 1400px) {
  #searchbarOuter {
    width: 37%;
  }
} */
/* @media only screen and (max-width: 1350px) {
  #searchbarOuter {
    width: 34%;
  }
} */
/* @media only screen and (max-width: 1300px) {
  #searchbarOuter {
    width: 34%;
  }
} */

@media only screen and (max-width: 600px) {
  .see-all-style {
    text-align: center !important;
  }
}

@media only screen and (max-width: 600px) {
  .hide-in-mbl{
    display: none !important;
  }
  #searchbarOuter{
    margin-top: 0px !important;
  }
}

.see-all-style{
  text-align: end;
  float: right;
  color: #406dc9;
  margin: 10px 0 !important;
}

@media only screen and (max-width: 600px) {
 .for-search{
   display: none !important;
 }
 .for-search-desktop{
   display: block !important;
   margin: 5px !important;
   font-weight: bold;
   cursor: pointer;
   font-weight: 400;
   border-radius: 1.2rem;
   opacity: 0.8;
   padding: 0.2rem 1rem;
   display: none;
   background: #406dc9;
   color: #FFF;
   font-size: 1rem;

 }
}

.navbar-brand {
  margin-left: 10%;
  margin-right: 20px;
}

.navbar-right {
  margin-right: 12%;
}

.searchIcon svg {
  color: #406dc9;
}

.input-group a{
  position: absolute;
}

.searchIcon {
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  /* background-color: var(--neddie-blue); */
  border: none;
  background-color: transparent;
  height: calc(1.5em + 0.75rem + 2px);
}

.searchbar {
  border-radius: 25px !important;
}

/* .searchIcon:hover {
  background-color: var(--neddie-darkblue);
} */

.input-group-append .input-group-text {
  border: none;
}

#searchbarOuter input::placeholder {
  font-size: medium;
}

@media (max-width: 550px) {
  #searchbarOuter input::placeholder {
    font-size: small;
  }

  #searchbarOuter {
    width: 60%;
    margin-left: 10px;
  }
  .searchbar-outer-donor {
    width: 100% !important;
  }

  #searchbarOuter .input-group-append {
    height: 30px;
  }

  #searchbarOuter .input-group input {
    height: 30px;
  }
}

@media (max-width: 400px) {
  /* #searchbarOuter {
    width: 50%;
  } */
}
