.desktop-install-popup-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0vw;
  width: 100%;
  height: 5vw;
  background-color: #406dc9;
  color: white;
  font-size: 2vw;
  font-weight: 400;
}

.desktop-install-popup-text {
  padding-left: 2vw;
  text-align: left;
  line-height: 1;
  vertical-align: auto;
}

@media (min-width: 3500px) {
  .desktop-install-popup-close-button Button {
    font-size: 1.5vw;
  }
}
