.dashboard-bottom-nav-container {
  height: 2.5vw;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  border-top: 0.1vw solid #d9dadb;
  background-color: #f8fafa;
  padding-right: 2vw;
}
.dashboard-bottom-nav-container .nav-link {
  font-size: 0.9vw;
  font-size: clamp(10px, 0.9vw, 50vw);
  color: #173269;
  font-weight: 700;
  padding-right: 2vw;
}
