.what-we-offer-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 35vh; */
  width: 30vw;
  padding: 5vh 4vw 5vh 4vw;
  box-sizing: border-box;
}

.wwo-img {
  height: 10vmin;
  margin-bottom: 2vh;
}

.wwo-title {
  color: #406dc9;
  font-family: 'Quicksand', sans-serif;
  font-size: 2.2vmin;
  font-weight: 800;
  margin-bottom: 2vh;
}

.wwo-desc {
  color: #707070;
  font-family: 'Quicksand', sans-serif;
  font-size: 2.2vmin;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .what-we-offer-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 30%;
    width: 95%;
    padding: 5% 5% 5% 5%;
    /* margin-left: 4rem */
    margin: auto;
  }

  .wwo-img {
    align-self: flex-start;
    height: 10vmin;
    margin: 0 10% 0 0;
  }

  .wwo-card-content-container {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .wwo-title {
    color: #173269;
    font-size: large;
    margin-bottom: 2%;
    text-align: left;
  }

  .wwo-desc {
    text-align: left;
    color: #173269;
    font-size: medium;
    font-weight: 600;
  }
}
