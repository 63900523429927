
.navbar-UserSetting-Modal .modal-dialog{
    max-width: 48% !important;
}
.navbar-UserSetting-Modal .modal-content{
    border-radius: 1.2rem;
    padding: 3rem 6rem 5rem 6rem;
    width: 100%;
    height: 100%;

}
.navbar-UserSetting-Modal .modal-header .close{
    font-size: 4rem;
    font-weight: 200;
    color: black;
    opacity:1;
    position: absolute;
    top:0;
    right:0;
    margin-right: 3%;
}
.navbar-UserSetting-Modal .modal-header{
    border: none;
    margin-bottom: 5rem;
}
.donorSetting-updatedGreenCheck {
    position: absolute;
    text-align: center;
    left: 0;
}
.donorSetting-updatedGreenCheck img{
    width: 15%;
   
}
.navbar-UserSetting-Modal .donorSetting-modal .form-group{
    margin-bottom: 2rem;
}

.navbar-UserSetting-Modal .Donor-setting-action-Buttons{
    margin-top: 3rem;
}
.navbar-UserSetting-Modal .Donor-setting-action-Buttons button {
    min-width: 55%;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 0.3rem;
    padding: 0.8rem;
}
.navbar-UserSetting-Modal label{
    font-size: 1.2rem;
    font-size: clamp(1rem,1.2vw,1.7rem);
    font-weight: 500;
}
.navbar-UserSetting-Modal .modal-content u{
    font-size: 1.3rem;
    font-size: clamp(1.1rem,1vw,1.4rem);
    font-weight: 500;
}
.navbar-UserSetting-Modal .modal-content u:hover{
    cursor: pointer;
}
.navbar-UserSetting-Modal .modal-content input {
    padding: 1.5rem;
    font-size: 1rem;
    font-size: clamp(1rem,1.1vw,1.8rem);
    border-radius: 0.7rem;
    font-weight: 600;
}

.navbar-UserSetting-Modal .modal-content input::placeholder{
    font-size: 1rem;
}
.donorSetting-modal-form .donorSetting-confirmPasswordCheck {
    background-color: white;
    border-left:none
}
.navbar-UserSetting-Modal .donorSettings-Validations{
    margin-top: 1rem;
}
.navbar-UserSetting-Modal .donorSettings-Validations small{
    display: block;
}

.navbar-UserSetting-Modal  .donorSetting-overall-validation-message small {
    background-color: rgb(255, 230, 230);
    padding: 2rem;
    width: 100%;
    font-size: 1.3rem;
    border-radius: 1rem;
    
}

@media (max-width:940px){
    .navbar-UserSetting-Modal .modal-dialog{
        max-width:90% !important;
    }

    .navbar-UserSetting-Modal .Donor-setting-action-Buttons button {
        min-width: auto;
        font-size: 12px;
        font-weight: 600;
        border-radius: 0.3rem;
        padding: 3px 20px;
    }
}
/* @media (max-width:530px){
    .navbar-UserSetting-Modal .modal-content{
        padding: 2rem 1rem 3rem 1rem;
    }
} */
@media (max-width: 570px) {
    .navbar-UserSetting-Modal .modal-dialog {
      margin: 2rem auto;
    }
    .navbar-UserSetting-Modal .modal-content{
        padding: 2rem 1rem 3rem 1rem;
    }
}