.manage-marketplace-h2 {
    font-size: 2vw;
    color: #173269;
    font-weight: 700;
    padding-left: 1vw;
  }
  .manage-marketplace-h5 {
    padding-top: 1vw;
    padding-left: 1vw;
    font-size: 1.2vw;
    font-size: clamp(5px, 1.2vw, 45px);
    color: #646464;
    font-weight: 600;
  }
  .manage-marketplace-info-container {
    width: 100%;
    height: 40vw;
    background-color: #ffff;
    border-radius: 1vw;
    padding: 3vw;
    margin-top: 1.5vw;
    font-weight: 600;
  }
  .manage-marketplace-info-container > h4 {
    font-size: 1.3vw;
    font-size: clamp(5px, 1.3vw, 45px);
    color: #262d33;
    font-weight: 700;
  }
  .manage-marketplace-info-container > p {
    font-size: 1vw;
    font-size: clamp(5px, 1vw, 45px);
    color: #b1b1b1;
    font-weight: 600;
  }
  .manage-marketplace-info-container > h3 {
    padding-top: 1vw;
    font-size: 1.2vw;
    font-size: clamp(5px, 1.2vw, 45px);
    color: #4592ff;
    font-weight: 600;
  }
  .manage-marketplace-info-container > h3:hover {
    cursor: pointer;
    color: #4188eb;
  }
  .manage-marketplace-addlist-form-container {
    display: flex;
    flex-direction: column;
    width: 50vw;
  }
  .manage-marketplace-addlist-form {
    display: flex;
    flex-direction: column;
    padding-top: 1.5vw;
  }
  .manage-marketplace-addlist-input-row {
    display: flex;
    flex-direction: row;
  }
  .manage-marketplace-info-icon-container {
    position: relative;
    z-index: 100;
  }
  .manage-marketplace-info-icon {
    width: 1.25vw;
    height: 1.25vw;
    z-index: 100;
  }
  .manage-marketplace-info-icon-container .manage-marketplace-tooltiptext {
    visibility: hidden;
    width: 10vw;
    height: 10vw;
    background-color: #ffff;
    color: #406dc9;
    text-align: left;
    border-radius: 1vw;
    padding: 0.25vw;
    border: 0.1vw solid #c6c6c6;
    bottom: -1vw;
    position: absolute;
    z-index: 100;
  }
  .manage-marketplace-info-icon-container .manage-marketplace-small-tooltiptext {
    visibility: hidden;
    width: 10vw;
    height: 5vw;
    background-color: #ffff;
    color: #406dc9;
    text-align: left;
    border-radius: 1vw;
    padding: 0.5vw;
    border: 0.1vw solid #c6c6c6;
    bottom: -1vw;
    position: absolute;
    z-index: 100;
  }
  .manage-marketplace-info-icon-container
    .manage-marketplace-tooltiptext
    .manage-marketplace-tooltiptext-h3 {
    font-size: 1vw;
    font-size: clamp(3px, 0.75vw, 50px);
    font-weight: 600;
    padding-bottom: 0.15vw;
  }
  .manage-marketplace-info-icon-container
    .manage-marketplace-tooltiptext
    .manage-marketplace-tooltiptext-p {
    font-size: 0.75vw;
    font-size: clamp(3px, 0.5vw, 50px);
  }
  .manage-marketplace-info-icon-container
    .manage-marketplace-small-tooltiptext
    .manage-marketplace-tooltiptext-h3 {
    font-size: 1vw;
    font-size: clamp(3px, 0.75vw, 50px);
    font-weight: 600;
    padding-bottom: 0.15vw;
  }
  .manage-marketplace-info-icon-container
    .manage-marketplace-small-tooltiptext
    .manage-marketplace-tooltiptext-p {
    font-size: 0.75vw;
    font-size: clamp(3px, 0.5vw, 50px);
  }
  .manage-marketplace-info-icon-container:hover .manage-marketplace-tooltiptext {
    visibility: visible;
  }
  .manage-marketplace-info-icon-container:hover
    .manage-marketplace-small-tooltiptext {
    visibility: visible;
  }
  .manage-marketplace-addlist-form label {
    color: #4b5157;
    font-size: 0.9vw;
    font-size: clamp(2px, 0.9vw, 40px);
    padding-top: 1vw;
  }
  .manage-marketplace-addlist-form-container h4 {
    position: relative;
    font-size: 1.3vw;
    font-size: clamp(5px, 1.3vw, 45px);
    color: #4b5157;
    font-weight: 700;
    padding-top: 2vw;
    padding-bottom: 0.25vw;
  }
  .manage-marketplace-addlist-form-container h4::before {
    position: absolute;
    content: '';
    bottom: 0;
    width: 1.5vw;
    left: 6%;
    border-bottom: 0.15vw solid #83bedf;
  }
  .manage-marketplace-addlist-form input {
    border: 0.75px solid #173269;
    border-radius: 0.25vw;
    width: 25vw;
    height: 2.5vw;
    color: #6a6969;
    font-size: 0.75vw;
    font-size: clamp(2px, 0.75vw, 40px);
    padding-left: 0.5vw;
  }
  .manage-marketplace-add-btn {
    margin-top: 3vw;
    border: none;
    outline: none;
    background-color: #406dc9;
    color: #ffff;
    font-size: 0.9vw;
    font-size: clamp(2px, 0.9vw, 50px);
    width: 8vw;
    height: 2vw;
    border-radius: 0.25vw;
  }
  .manage-marketplace-add-btn:active {
    outline: none;
  }
  .manage-marketplace-add-btn:hover {
    background-color: #3b66bd;
  }
  .manage-marketplace-cancel-btn {
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 0.9vw;
    font-size: clamp(2px, 0.9vw, 50px);
    color: #406dc9;
    width: 8vw;
    height: 2vw;
  }
  .manage-marketplace-cancel-btn:hover {
    color: #3b66bd;
  }
  .manage-marketplace-cancel-btn:active {
    outline: none;
  }
  .savingMarketplace-spinner-container {
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 100;
    width: 100%;
    height: 100%;
  
    /* background-color: rgba(0, 0, 0, 0.25); */
  }
  .savingMarketplace-spinner-content {
    background-color: #f5f4f4;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 30%;
    left: 40%;
    width: 30vw;
    height: 10vw;
    padding: 20px;
    border-radius: 5px;
    border: 0.1vw solid #c6c6c6;
    font-size: 1vw;
    font-size: clamp(5px, 1vw, 50px);
  
    color: #406dc9;
  }
  