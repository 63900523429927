.side-nav-settings-page-container {
  display: flex;
  flex-direction: row;
  /* padding: 2vw; */
}
.side-nav-settings-page-container * input:focus {
  outline: none;
}
.side-nav-settings-page-container * button:focus {
  outline: none;
}
.side-nav-settings-page-left-col {
  display: flex;

  flex-direction: column;
  width: 30vw;
  margin-left: 5vw;
}
.side-nav-settings-page-right-col {
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin-left: 5vw;
}
.side-nav-settings-page-right-col h2 {
  display: inline-block;
  position: relative;
  font-size: 1.4vw;
  font-size: clamp(5px, 1.4vw, 50px);
  font-weight: 700;
  padding-bottom: 1vw;
}
.side-nav-settings-page-right-col h2::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 1.5vw;
  left: 0%;
  border-bottom: 0.2vw solid #7ae2ae;
}
.side-nav-settings-image-logo {
  height: 5vw;
    width: 5vw;
    border-radius: 50px;
    margin-top: 30px
}
.side-nav-settings-logo-container {
  flex-direction: row;
}
.side-nav-settings-logo-edit-btn {
  width: 8vw;
  height: 2.5vw;
  margin-left: 1vw;
  border-radius: 0.75vw;
  background-color: transparent;
  border: 0.15vw solid #406dc9;
  color: #406dc9;
  font-size: 0.8vw;
  font-size: clamp(3px, 0.8vw, 40px);
  font-weight: 600;
}
.side-nav-settings-logo-edit-btn:hover {
  background-color: #406dc9;
  color: #ffff;
}
.side-nav-settings-user-info {
  display: flex;
  flex-direction: column;
  padding-top: 1vw;
}
.side-nav-settings-user-info h2 {
  display: inline-block;
  position: relative;
  font-size: 1.4vw;
  font-size: clamp(5px, 1.4vw, 50px);
  font-weight: 700;
  padding-bottom: 1vw;
}
.side-nav-settings-user-info h2::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 1.5vw;
  left: 0%;
  border-bottom: 0.2vw solid #7ae2ae;
}
.side-nav-settings-user-info-form {
  padding-top: 3vw;
  display: flex;
  flex-direction: column;
}
.side-nav-settings-user-info-form h5 {
  color: #141212;
  font-size: 20px;
  font-weight: 700;
}
.side-nav-settings-account-info-form h5 {
  color: #141212;
  font-size: 20px;
  /* font-size: clamp(2px, 1.1vw, 50px); */
  font-weight: 700;
}
.side-nav-settings-info-div {
  height: auto;
  margin-bottom: 2vw;
}
.side-nav-settings-user-info-form p {
  display: flex;
  flex-direction: column;
  color: #363535 !important;
  font-size: 20px;
  font-weight: 600 !important;
  /* padding-bottom: 2vw; */
  /* padding-left: 1vw; */
  margin-bottom: 5px !important;
}
.side-nav-settings-account-info-form p {
  display: flex;
  flex-direction: column;
  color: #363535 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  /* padding-bottom: 2vw; */
  /* padding-left: 1vw; */
  margin-bottom: 5px !important;
}
.side-nav-settings-user-info-form input {
  margin-bottom: 1vw;
  /* width: 25vw; */
  /* height: 2.5vw; */
  /* border-radius: 1vw; */
  border: none;
  background: rgb(241, 240, 241);
  font-size: 1.25vw;
  font-size: clamp(4px, 1.25vw, 50px);
  padding: 0.5vw;
}
.side-nav-settings-user-info-form input::placeholder {
  font-size: 18px;
  color: #000;
}
.side-nav-settings-password-btn {
  width: 9vw;
  height: 2.5vw;
  margin-top: 2vw;
  border-radius: 0.75vw;
  background-color: transparent;
  border: 0.15vw solid #406dc9;
  color: #406dc9;
  font-size: 0.8vw;
  font-size: clamp(3px, 0.8vw, 40px);
  font-weight: 600;
}
.side-nav-settings-password-btn:hover {
  background-color: #406dc9;
  color: #ffff;
}
.side-nav-settings-account-info {
  padding-top: 6.8vw;
  padding-bottom: 0vw;
  display: flex;
  flex-direction: column;
}
.side-nav-settings-save-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 4vw;
  width: 100%;
}
.side-nav-settings-save-btn-container button {
  width: 8vw;
  height: 2.5vw;
  border-radius: 6px;
  background-color: #406dc9;
  border: none;
  color: #fff;
  font-size: 0.8vw;
  font-size: clamp(3px, 0.8vw, 40px);
  font-weight: 600;
}
.side-nav-settings-save-btn-container button:hover {
  background-color: #3a63b6;
}
.side-nav-settings-account-info-form {
  padding-top: 3vw;
  display: flex;
  flex-direction: column;
}

.side-nav-settings-account-info-form
  .side-nav-settings-account-info-form-top
  input {
    margin-bottom: 1vw;
    width: 100%;
    /* height: 2.5vw; */
    /* border-radius: 1vw; */
    border: none;
    background: rgb(241, 240, 241);
    font-size: 1.25vw;
    font-size: clamp(4px, 1.25vw, 50px);
    padding: 0.5vw;
}
.side-nav-settings-account-info-form-top input::placeholder {
  font-size: 1.25vw;
  color: #000;
}
.side-nav-settings-account-info-form-top {
  display: flex;
  flex-direction: row;
}
.side-nav-settings-account-info-form-bottom {
  display: flex;
  flex-direction: column;
}
.side-nav-settings-account-info-form-bottom input {
  margin-bottom: 1vw;
  width: 100%;
    /* height: 2.5vw; */
    /* border-radius: 1vw; */
    border: none;
    background: rgb(241, 240, 241);
    font-size: 1.25vw;
    font-size: clamp(4px, 1.25vw, 50px);
    padding: 0.5vw;
}
.side-nav-settings-account-info-form-bottom input::placeholder {
  font-size: 1.25vw;
  color: #000;
}
.side-nav-settings-password {
  display: flex;
  flex-direction: column;
}
.side-nav-settings-delete-account-btn {
  align-self: flex-end;
  width: 9vw;
  height: 2.5vw;
  margin-top: 2vw;
  border-radius: 6px;
  background-color: transparent;
  border: 0.15vw solid #406dc9;
  color: #fff;
  font-size: 0.8vw;
  font-size: clamp(3px, 0.8vw, 40px);
  font-weight: 600;
  background-color: #406dc9;
}
.side-nav-settings-delete-account-btn:hover {
  background-color: #406dc9;
  color: #ffff;
}
.settings-confirm-delete-container {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.3);
}
.settings-confirm-delete-content {
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  width: 800px;
  height: auto;
  padding: 0.5vw;
  border-radius: 20px;
  border: 0.1vw solid #c6c6c6;
  font-size: 1vw;
  font-size: clamp(5px, 1vw, 50px);
  color: #4b5157;
  transform: translate(-50%, -50%);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.settings-confirm-delete-content .confrom-title{
  font-size: 28px;
  font-weight: 700;
    font-style: normal;
    color: rgb(0, 0, 0);
    text-decoration: none;
}
.settings-confirm-delete-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 0;
  margin-top: 3vw;
  gap: 20px;
}
.settings-confirm-keep-btn {
  border: 0.1vw solid #406dc9;
  border-radius:6px;

  background-color: #fff;
  color: #406dc9;
  width: 8vw;
  height: 2.5vw;
  font-size: 0.9vw;
  font-size: clamp(3px, 0.9vw, 40px);
}
.settings-confirm-delete-btn {
  border-radius: 6px;
  border: 0;
  background-color: #406dc9;
  color: #fff;
  width: 8vw;
  height: 2.5vw;
  font-size: 0.9vw;
  font-size: clamp(3px, 0.9vw, 40px);
}
.settings-confirm-delete-content-inner h6 {
  position: relative;
  font-size: 1.3vw;
  font-size: clamp(5px, 1.3vw, 45px);
  color: #4b5157;
  font-weight: 700;
  padding-top: 0.5vw;
  padding-bottom: 1vw;
  padding-left: 2vw;
  width: 100%;
}
/* .settings-confirm-delete-content-inner h2::before {
  position: absolute;
  content: '';
  bottom: 0;
  width: 10vw;
  left: 0%;
  border-bottom: 0.15vw solid #b0b5b9;
} */
.settings-confirm-delete-content-inner-p {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding-bottom: 3vw;
  padding-top: 3vw;
}
.settings-confirm-delete-content-inner-p p {
  font-size: 1vw;
  font-size: clamp(5px, 1vw, 50px);
  font-weight: 500;
  margin-bottom: 0;
}
.settings-confirm-delete-content-inner {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding-bottom: 4vw;
  padding-top: 4vw;
}

.settings-confirm-delete-content-inner .goodbye{
  font-size: 28px;
    font-weight: 500;
    font-style: normal;
    color: rgb(0, 0, 0);
    text-decoration: none;
    width: 65%;
    margin-inline: auto;
}
.settings-confirm-delete-content-inner .buttonn{
  margin-top: 3vw;
}
.settings-confirm-delete-content-inner .buttonn button{
  border-radius: 6px;
    border: 0;
    background-color: #406dc9;
    color: #fff;
    width: 8vw;
    height: 2.5vw;
    font-size: 0.9vw;
    font-size: clamp(3px, 0.9vw, 40px);
}
.settings-confirm-delete-warning-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 1vw;
}
.settings-confirm-delete-warning-icon {
  height: 3vw;
  width: 3vw;
}
.settings-confirm-delete-content-inner-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;
}
.settings-confirm-delete-content-inner-top button {
  background-color: transparent;
  margin-left: 10vw;
  color: #406dc9;
  border: 0;
  font-size: 1.5vw;
  font-size: clamp(5px, 1.5vw, 50px);
}
.change-password-container {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);
}
.change-password-content {
  background-color: #f5f4f4;
  position: absolute;
  display: flex;
  flex-direction: column;

  align-items: center;
  top: 25%;
  left: 35%;
  width: 30vw;
  height: 35vw;
  padding: 1.5vw;
  border-radius: 0.5vw;
  border: 0.1vw solid #c6c6c6;
  font-size: 1vw;
  font-size: clamp(5px, 1vw, 50px);

  color: #4b5157;
}
.change-password-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 2vw;
  margin-bottom: 1vw;
}
.change-password-btn {
  border-radius: 0.5vw;
  border: 0;
  background-color: #406dc9;
  color: #fff;
  width: 12vw;
  height: 2.5vw;
}

.change-password-content-inner h1 {
  font-size: 1.25vw;
  font-size: clamp(5px, 1.25vw, 45px);
  color: #4b5157;
  font-weight: 700;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

.change-password-content-inner p {
  font-size: 0.9vw;
  font-size: clamp(5px, 0.9vw, 50px);
  color: #406dc9;
  font-weight: 700;
  padding-top: 1vw;
}
.change-password-content-inner {
  display: flex;
  flex-direction: column;

  width: 100%;

  text-align: left;
}
.change-password-content-inner-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;
}
.change-password-content-inner-top button {
  background-color: transparent;
  margin-left: 10vw;
  color: #406dc9;
  border: 0;
  font-size: 1.5vw;
  font-size: clamp(5px, 1.5vw, 50px);
}
.change-password-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.change-password-form input {
  border: 0.1vw solid #406dc9;
  border-radius: 0.5vw;
  font-size: 1vw;
  height: 2vw;
  font-size: clamp(5px, 1vw, 50vw);
}

.change-password-success-container {
  display: flex;
  flex-direction: column;
  width: 18vw;
  height: 20vw;
  align-content: center;
  margin-top: 5vw;
  text-align: center;
}
.change-password-success-container p {
  font-size: 1.5vw;
  font-size: clamp(5px, 1.5vw, 50px);
  font-weight: 700;
}
.change-password-success-container img {
  width: 5vw;
  height: 5vw;
  margin-top: 1vw;
  align-self: center;
}
/* .changePassword-success-container-icon {
  width: 3vw;
  height: 3vw;
  margin-top: 1vw;
} */
.edit-image-container {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 100;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);
}
.edit-image-content {
  background-color: #f5f4f4;
  position: absolute;
  display: flex;
  flex-direction: column;

  align-items: center;
  top: 30%;
  left: 40%;
  width: 30vw;
  height: 20vw;
  padding: 1.5vw;
  border-radius: 0.5vw;
  border: 0.1vw solid #c6c6c6;
  font-size: 1vw;
  font-size: clamp(5px, 1vw, 50px);

  color: #4b5157;
}
.edit-image-content-inner-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;
}
.edit-image-content-inner-top button {
  background-color: transparent;
  margin-left: 10vw;
  color: #bcbfc2;
  border: 0;
  font-size: 1.5vw;
  font-size: clamp(5px, 1.5vw, 50px);
}
.edit-image-content-inner {
  display: flex;
  flex-direction: column;

  width: 100%;
}
.edit-image-content-inner-header {
  color: #406dc9;
  font-size: 1.25vw;
  font-size: clamp(5px, 1.25vw, 50px);
  font-weight: 600;
}
.dashboard-edit-image-form {
  display: flex;
  flex-direction: row;
  margin-top: 1vw;
}
.dashboard-edit-image-form p {
  font-size: 1vw;
  font-size: clamp(3px, 1vw, 50px);
  font-weight: 600;
  color: #406dc9;
}
.dashboard-edit-image-form input {
  border: 0.1vw solid #c6c6c6;
  font-size: 1vw;
  height: 2vw;
  font-size: clamp(5px, 1vw, 50vw);
  margin-left: 1vw;
  padding-left: 0.5vw;
}
.dashboard-edit-image-btn {
  border: none;
  background-color: #406dc9;
  font-size: 0.9vw;
  font-size: clamp(3px, 0.9vw, 40px);
  color: #fff;
  border-radius: 1vw;
  width: 6vw;
  height: 2.25vw;
  margin-left: 1vw;
}
.dashboard-edit-image-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 3vw;
}
.dashboard-edit-image-btn-container button {
  border: none;
  background-color: #406dc9;
  font-size: 1.25vw;
  font-size: clamp(3px, 1.25vw, 40px);
  color: #fff;
  border-radius: 0.5vw;
  width: 10vw;
  height: 3vw;
}

.side-nav-settings-account-info-form p label{
  margin: 0px !important;
}

.side-nav-settings-user-info-form p label{
  margin: 0px !important;
}


.dashboard-setting-popups .modal-content{
    border-radius: 20px;
  }
  .dashboard-setting-popups .manage-marketplace-info-container{
    width: 100%;
    height: auto !important;
    background-color: #ffff;
    border-radius: 1vw;
    padding: 3vw;
    margin-top: 1.5vw;
    font-weight: 600;
  }

  .dashboard-setting-popups .modal-dialog {
      max-width: 95vw
  }

  .dashboard-setting-popups .modal-content .manage-wish-wrap{
    padding: 30px;
    font-weight: 500;
    font-style: normal;
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 27px;
    position: relative;
    display: flex;
  }

  .dashboard-setting-popups .modal-content .manage-wish-wrap.left{
    width: 30%;
  }
  .dashboard-setting-popups .modal-content .manage-wish-wrap.right{
    width: 70%;
  }

  .dashboard-setting-popups .manage-marketplace-h2 {
    font-size: 31px;
    font-weight: 700;
    font-style: normal;
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 0;
    margin: 0;
}

.dashboard-setting-popups .manage-marketplace-addlist-form label {
  font-size: 26px;
  padding-top: 1vw;
  font-weight: 500;
    font-style: normal;
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.dashboard-setting-popups  .manage-marketplace-addlist-form input {
  border: 1px solid #000;
  border-radius: 0.25vw;
  width: 80%;
  height: auto;
  color: #6a6969;
  background-color: rgb(241, 240, 241);
  padding-left: 0.7vw 0.5vw;
  font-weight: 500;
    font-style: normal;
    color: #000;
    text-decoration: none;
    font-size: 26px;
    margin-bottom: 20px;
}


.dashboard-setting-popups  .manage-marketplace-addlist-form input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #B2AFB2;
  font-size: 26px;
}

.dashboard-setting-popups  .manage-marketplace-addlist-form input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #B2AFB2;
  font-size: 26px;
}

.dashboard-setting-popups .neddie-button{
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  font-size: 26px;
  font-weight: 600;
  padding: 4px 36px;
  border: 1px solid var(--neddie-blue);
  border-radius: 50vw;
  color: white;
  background: var(--neddie-blue);
  border-color: var(--neddie-blue);
  margin-top: 5px;
  width: fit-content;
}

.dashboard-setting-popups .cancle-popup{
  position: absolute;
  top: 17px;
  right: 25px;
  font-size: 34px;
  padding: 10px;
  line-height: 16px;
  cursor: pointer;
}


.dashboard-setting-popups .left{
  width:27%;
  margin-top: 20px;
}
.dashboard-setting-popups .left .tab-listing{
  margin-top: 40px;
}
.dashboard-setting-popups .left .tab-listing ul{
  margin: 0;
  padding: 0;
}
.dashboard-setting-popups .left .tab-listing ul li{
  list-style: none;
  font-weight: 500;
    font-style: normal;
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.dashboard-setting-popups .left .tab-listing ul li:hover{
  font-weight: 700;
}
.dashboard-setting-popups .left .tab-listing ul li.active{
  font-weight: 700;
}

.dashboard-setting-popups .left .tab-listing ul li:nth-child(1){}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(1) svg{
  color: #94989b;
    font-size: 16px;
    margin-bottom: 4px;
    margin-right: 7px;
    margin-left: 2px;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(1):hover svg{
  color: #406dc9;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(2):hover svg{
  color: #406dc9;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(1).active svg{
  color: #406dc9;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(2).active svg{
  color: #406dc9;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(2){}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(2) svg{
  color: #94989b;
    font-size: 16px;
    margin-bottom: 4px;
    margin-right: 7px;
    margin-left: 2px;
}
/* .dashboard-setting-popups .left .tab-listing ul li:nth-child(3){
  display: flex;
    align-items: center;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(3) span{
  background-color: #94989b;
  margin-right: 7px;
  padding: 0px 4px;
    border-radius: 25px;
    height: 21px !important;
    display: inline-block;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(3).active span{
  background-color: #406dc9;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(3) span svg{
  color: #fff;
  margin-bottom: 14px;
  font-size: 12px;
    
} */
.dashboard-setting-popups .left .tab-listing ul li:nth-child(3){
  display: flex;
  align-items: center;
}

.dashboard-setting-popups .left .tab-listing ul li:nth-child(1) a{
  text-decoration: none;
  color: #94989b;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(1) a{
  text-decoration: none;
  color: #000;
}
.dashboard-setting-popups .left .tab-listing ul li a{
  text-decoration: none;
  color: #94989b;
}
.dashboard-setting-popups .left .tab-listing ul li a{
  text-decoration: none;
  color: #000;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(3) a{
  text-decoration: none;
  color: #94989b;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(3) a{
  text-decoration: none;
  color: #000;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(3) a span{
  background-color: #94989b;
    font-size: 14px;
    color: #fff;
    padding: 2px 9px;
    border-radius: 25px;
    margin-right: 7px;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(3).active span{
  background-color: #406dc9;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(3):hover span{
  background-color: #406dc9;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(4){}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(4) svg{
  color: #94989b;
  font-size: 16px;
  margin-bottom: 4px;
  margin-right: 7px;
  margin-left: 2px;
}

.dashboard-setting-popups .left .tab-listing ul li:nth-child(4) a{
  color: #000;
  text-decoration: none;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(4):hover a{
  color: #000;
  text-decoration: none;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(4).active span{
  color: #406dc9;
}
.dashboard-setting-popups .left .tab-listing ul li:nth-child(4):hover svg{
  color: #406dc9;
}

.dashboard-setting-popups .center{
  width: 0.2%;
  /* height: 100%; */
  background-color: rgb(241, 240, 241);
}
.dashboard-setting-popups .right{
  width: 69%;
  margin-top: 20px;
}

.pass-reset-deshboard{}
.pass-reset-deshboard h1{
  font-size: 31px;
    font-weight: 700;
    font-style: normal;
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 0;
    margin: 0px 0px 0px 50px;
}

.pass-reset-deshboard .change-password-content-inner p{
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 26px !important;
  margin-bottom: 2px !important;
  padding-top: 0px !important;
}

.pass-reset-deshboard .change-password-form input{
  margin-bottom: 1vw;
  width: 100% !important;
  height: auto !important;
  border-radius: 0px !important;
  border: none;
  background: rgb(241, 240, 241) !important;
  font-size: 1.25vw;
  font-size: clamp(4px, 1.25vw, 50px);
  padding: 0.5vw;
  margin-bottom: 8px !important;
}

.pass-reset-deshboard small{
  font-size: 14px !important;
}

.pass-reset-deshboard .col{
  margin-top: -20px;
}

.pass-reset-deshboard .change-password-btn-container{
justify-content: center !important;
}

.pass-reset-deshboard .change-password-btn {
  border-radius: 0.5vw;
  border: 0;
  background-color: #406dc9;
  color: #fff;
  width: auto;
  height: auto;
  padding-inline: 30px;
}

.dashboard-info-h2 {
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 26px;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .dashboard-setting-popups .cancle-popup{
    position: absolute;
    top: 7px;
    right: 9px;
  }

  .dashboard-setting-popups .left {
    width: 100%;
    margin-top: 10px;
}

.dashboard-setting-popups .modal-dialog {
  max-width: 100vw
}

.dashboard-setting-popups .manage-marketplace-h2 {
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.dashboard-setting-popups .left .tab-listing ul li {
  list-style: none;
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  margin-left: 30%;
}

.dashboard-setting-popups .left .tab-listing {
  margin-top: 30px;
}

.dashboard-setting-popups .modal-content {
  border-radius: 16px;
}

.side-nav-settings-page-left-col {
  width: 50%;
  margin: 0;
}

.side-nav-settings-page-right-col {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 0vw;
}

.side-nav-settings-page-container{
  margin-top: 30px;
  /* margin-bottom: 30px; */
  padding-inline: 20px;
  gap: 15px;
}

.side-nav-settings-account-info-form {
  padding-top: 53px;
}

.manage-marketplace-h2 {
  font-size: 22px;
  color: #000;
  font-weight: 700;
  padding-left: 1vw;
}

.side-nav-settings-image-logo {
  height: 45px;
  width: 45px;
  margin-top: 5px;
}

.side-nav-settings-user-info-form h5 {
  color: #141212;
  font-size: 15px;
  font-weight: 700;
}

.side-nav-settings-account-info-form p {
  display: flex;
  flex-direction: column;
  color: #363535 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  /* padding-bottom: 2vw; */
  /* padding-left: 1vw; */
  margin-bottom: 5px !important;
  word-wrap: break-word;
}

.side-nav-settings-user-info-form p {
  display: flex;
  flex-direction: column;
  color: #363535 !important;
  font-size: 12px;
  font-weight: 600 !important;
  /* padding-bottom: 2vw; */
  /* padding-left: 1vw; */
  margin-bottom: 5px !important;
}

.side-nav-settings-account-info-form h5 {
  color: #141212;
  font-size: 14px;
  /* font-size: clamp(2px, 1.1vw, 50px); */
  font-weight: 700;
}

.side-nav-settings-save-btn-container button {
  width: auto;
  height: auto;
  border-radius: 4px;
  background-color: #406dc9;
  border: none;
  color: #fff;
  font-size: 0.8vw;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 15px;
}

.side-nav-settings-save-btn-container{
  justify-content: center;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.mobile-account-cross{
  font-size: 26px;
    color: #000;
    font-weight: 700;
    text-align: right;
    line-height: 20px;
    margin-right: 0px;
}

.side-nav-settings-user-info-form input {
  font-size: 12px;
  padding: 5px 10px;
}
.side-nav-settings-account-info-form-bottom input {
  font-size: 12px;
  padding: 5px 10px;
}

.side-nav-settings-delete-account-btn {
  align-self: flex-end;
  width: 100%;
  height: auto;
  margin-top: 6vw;
  border-radius: 4px;
  background-color: transparent;
  border: 0.15vw solid #406dc9;
  color: #fff;
  font-size: 0.8vw;
  font-size: 14px;
  font-weight: 600;
  background-color: #406dc9;
}

.settings-confirm-delete-content {
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  width: 95%;
  height: auto;
  padding: 0.5vw;
  border-radius: 12px;
  border: 0.1vw solid #c6c6c6;
  font-size: 1vw;
  font-size: clamp(5px, 1vw, 50px);
  color: #4b5157;
  transform: translate(-50%, -50%);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.settings-confirm-delete-content .confrom-title {
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 70%;
  margin-inline: auto;
}

.settings-confirm-delete-btn {
  border-radius: 6px;
  border: 0;
  background-color: #406dc9;
  color: #fff;
  width: auto;
  height: auto;
  font-size: 12px;
  padding: 2px 15px;
}

.settings-confirm-keep-btn {
  border: 0.1vw solid #406dc9;
  border-radius: 6px;
  background-color: #fff;
  color: #406dc9;
  width: auto;
  height: auto;
  font-size: 12px;
  padding: 2px 15px;
}

.settings-confirm-delete-content-inner .goodbye {
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 75%;
  margin-inline: auto;
}

.settings-confirm-delete-content-inner .buttonn button {
  border-radius: 6px;
  border: 0;
  background-color: #406dc9;
  color: #fff;
  width: auto;
  height: auto;
  font-size: 12px;
  padding: 2px 15px;
}

.pass-reset-deshboard{
  margin-top: 30px;
  margin-bottom: 30px;
  padding-inline: 20px;
}
.pass-reset-deshboard .title-cross{
  display: flex;
  justify-content: space-between;
}

.pass-reset-deshboard h1 {
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0;
  margin: 0;
}
.pass-reset-deshboard span {
  font-size: 26px;
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0;
  margin: 0px 0px 0px 0px;
  line-height: 20px;
}

.pass-reset-deshboard .change-password-content-inner p {
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 18px !important;
  margin-bottom: 2px !important;
  padding-top: 0px !important;
}

.pass-reset-deshboard .change-password-form input {
  margin-bottom: 1vw;
  width: 98% !important;
  height: auto !important;
  border-radius: 0px !important;
  border: none;
  background: rgb(241, 240, 241) !important;
  font-size: 1.25vw;
  font-size: 16px;
  padding: 5px 15px;
  margin-bottom: 8px !important;
  margin-top: 5px;
}

.pass-reset-deshboard .change-password-btn {
  border-radius: 0.5vw;
  border: 0;
  background-color: #406dc9;
  color: #fff;
  width: auto;
  height: auto;
  padding-inline: 30px;
  font-size: 14px;
  margin-top: 30px;
}

.change-password-content-inner {
  display: flex;
  /* flex-direction: column; */
  width: 80%;
  text-align: left;
  margin-inline: auto;
}

.change-password-success-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 20vw;
  align-content: center;
  margin-top: 15vw;
  text-align: center;
}

.change-password-success-container p {
  font-size: 1.5vw;
  font-size: 20px;
  font-weight: 700;
}

.change-password-success-container img {
  width: 30vw;
  height: 30vw;
  margin-top: 1vw;
  align-self: center;
}
}

.mobile-setting-popups .modal-content{
  border-radius: 20px;
}
.mobile-setting-popups .manage-marketplace-info-container{
  width: 100%;
  height: auto !important;
  background-color: #ffff;
  border-radius: 1vw;
  padding: 3vw;
  margin-top: 1.5vw;
  font-weight: 600;
}

.mobile-setting-popups .modal-dialog {
    max-width: 40vw
}

.mobile-setting-popups .modal-content .manage-wish-wrap{
  padding: 30px;
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 27px;
  position: relative;
  display: flex;
}

.mobile-setting-popups .modal-content .manage-wish-wrap.left{
  width: 30%;
}
.mobile-setting-popups .modal-content .manage-wish-wrap.right{
  width: 70%;
}

.mobile-setting-popups .manage-marketplace-h2 {
  font-size: 31px;
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.mobile-setting-popups .manage-marketplace-addlist-form label {
font-size: 26px;
padding-top: 1vw;
font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.mobile-setting-popups  .manage-marketplace-addlist-form input {
border: 1px solid #000;
border-radius: 0.25vw;
width: 80%;
height: auto;
color: #6a6969;
background-color: rgb(241, 240, 241);
padding-left: 0.7vw 0.5vw;
font-weight: 500;
  font-style: normal;
  color: #000;
  text-decoration: none;
  font-size: 26px;
  margin-bottom: 20px;
}


.mobile-setting-popups  .manage-marketplace-addlist-form input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
color: #B2AFB2;
font-size: 26px;
}

.mobile-setting-popups  .manage-marketplace-addlist-form input:-moz-placeholder,
textarea:-moz-placeholder {
color: #B2AFB2;
font-size: 26px;
}

.mobile-setting-popups .neddie-button{
transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
font-size: 26px;
font-weight: 600;
padding: 4px 36px;
border: 1px solid var(--neddie-blue);
border-radius: 50vw;
color: white;
background: var(--neddie-blue);
border-color: var(--neddie-blue);
margin-top: 5px;
width: fit-content;
}

.mobile-setting-popups .cancle-popup{
position: absolute;
top: 17px;
right: 25px;
font-size: 34px;
padding: 10px;
line-height: 16px;
cursor: pointer;
}


.mobile-setting-popups .left{
width:100%;
margin-top: 20px;
}
.mobile-setting-popups .left .tab-listing{
margin-top: 40px;
margin-left: 40%;
}
.mobile-setting-popups .left .tab-listing ul{
margin: 0;
padding: 0;
}
.mobile-setting-popups .left .tab-listing ul li{
list-style: none;
font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.mobile-setting-popups .left .tab-listing ul li:hover{
font-weight: 700;
}
.mobile-setting-popups .left .tab-listing ul li.active{
font-weight: 700;
}

.mobile-setting-popups .left .tab-listing ul li:nth-child(1){}
.mobile-setting-popups .left .tab-listing ul li:nth-child(1) svg{
color: #94989b;
  font-size: 16px;
  margin-bottom: 4px;
  margin-right: 7px;
  margin-left: 2px;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(1):hover svg{
color: #406dc9;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(2):hover svg{
color: #406dc9;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(1).active svg{
color: #406dc9;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(2).active svg{
color: #406dc9;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(2){}
.mobile-setting-popups .left .tab-listing ul li:nth-child(2) svg{
color: #94989b;
  font-size: 16px;
  margin-bottom: 4px;
  margin-right: 7px;
  margin-left: 2px;
}
/* .mobile-setting-popups .left .tab-listing ul li:nth-child(3){
display: flex;
  align-items: center;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(3) span{
background-color: #94989b;
margin-right: 7px;
padding: 0px 4px;
  border-radius: 25px;
  height: 21px !important;
  display: inline-block;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(3).active span{
background-color: #406dc9;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(3) span svg{
color: #fff;
margin-bottom: 14px;
font-size: 12px;
  
} */
.mobile-setting-popups .left .tab-listing ul li:nth-child(3){
display: flex;
align-items: center;
}

.mobile-setting-popups .left .tab-listing ul li:nth-child(2) a{
text-decoration: none;
color: #94989b;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(1) a{
text-decoration: none;
color: #94989b;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(3) a{
text-decoration: none;
color: #94989b;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(2) a{
text-decoration: none;
color: #000;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(1) a{
text-decoration: none;
color: #000;
}

.mobile-setting-popups .left .tab-listing ul li:nth-child(3) a{
text-decoration: none;
color: #000;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(3) a span{
background-color: #94989b;
  font-size: 14px;
  color: #fff;
  padding: 2px 9px;
  border-radius: 25px;
  margin-right: 7px;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(3).active span{
background-color: #406dc9;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(3):hover span{
background-color: #406dc9;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(4){}
.mobile-setting-popups .left .tab-listing ul li:nth-child(4) svg{
color: #94989b;
font-size: 16px;
margin-bottom: 4px;
margin-right: 7px;
margin-left: 2px;
}

.mobile-setting-popups .left .tab-listing ul li:nth-child(4) a{
color: #000;
text-decoration: none;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(4):hover a{
color: #000;
text-decoration: none;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(4).active span{
color: #406dc9;
}
.mobile-setting-popups .left .tab-listing ul li:nth-child(4):hover svg{
color: #406dc9;
}

.mobile-setting-popups .center{
/* width: 0.2%; */
/* height: 100%; */
background-color: rgb(241, 240, 241);
}
.mobile-setting-popups .right{
width: 69%;
margin-top: 20px;
}