#root {
  height: 100%;
}

.dashboard-container-outer {
  /* margin-top: 6vw;
  margin-top: clamp(80px, 6vw, 300px); */
  width: 100%;
  height: 100%;
}

.dashboard-display-container {
  background-color: #f8fafa;
  margin: 1vw 2vw 0 1vw;
  width: 78vw;
  min-width: 500px;
  height: fit-content;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  background-color: #f8fafa;
  padding-right: 0;
  height: 100%;
  width: 100%;
}

.dashboard-copyright {
  height: 2.5vw;
  display: flex;
  align-items: center;
  border-top: 0.1vw solid #d9dadb;
  background-color: #f8fafa;
  width: 50vw;
}

.dashboard-copyright p {
  padding-left: 1vw;
  color: #173269;
  font-size: 0.75vw;
  font-size: clamp(2px, 0.75vw, 25px);
  font-weight: 600;
}

.dashboard-bootom-nav {
  width: 100vw;
}

.dashboard-container-row {
  display: flex;
  flex-direction: row;
  height: fit-content;
}

.dashboard-container-dashboard-options {
  width: 22vw;
  min-width: 200px;
  height: 100vh;
}

.dashboard-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: sticky;
  bottom: 0
}

/* .side-nav-container {
  min-height: 95vh;
  min-height: calc(95vh - clamp(80px, 6vw, 300px));
} */

.dashboard-copyright,
.dashboard-bottom-nav-container {
  min-height: 5vh;
}

.new-dashboard-layout {
  padding: 30px 40px;
  background: #4874CD;
}

.new-dashboard-layout .dashboard-sec-1 {}

.new-dashboard-layout .dashboard-sec-1 .title {}

.new-dashboard-layout .dashboard-sec-1 .title h1 {
  font-weight: 700;
  font-style: normal;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 24px;
}

.new-dashboard-layout .dashboard-sec-1 .dashboard {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.new-dashboard-layout .dashboard-sec-1 .dashboard img {
  width: 22px
}

.new-dashboard-layout .dashboard-sec-1 .dashboard span {
  font-weight: 500;
  font-style: normal;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 22px;
}

.new-dashboard-layout .dashboard-sec-2 {}

.new-dashboard-layout .dashboard-sec-2 .need-help {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  margin-bottom: 15px;
}

.new-dashboard-layout .dashboard-sec-2 .need-help a {
  font-weight: 500;
  font-style: normal;
  color: rgb(255, 255, 255);
  text-decoration: underline;
  font-size: 20px;
  text-align: right;
}

.new-dashboard-layout .dashboard-sec-2 .need-help span {
  background-color: #fff;
  border-radius: 25px;
  font-weight: 700;
  font-style: normal;
  color: #2e75cd;
  text-decoration: underline;
  font-size: 14px;
  text-align: right;
  padding: 0px 8px;
  text-decoration: none;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap {
  display: flex;
  gap: 15px;

}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div {
  flex: 1 1;
  background: #fff;
  text-align: center;
  border-radius: 16px;
  /* height: 120px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  padding: 10px 10px;
  cursor: pointer;
  justify-content: space-between;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  justify-content: space-between;
  /* gap: 10px; */
  text-decoration: none;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div a:hover {
  text-decoration: none;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div:nth-child(1) img {
  width: 50px;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div:nth-child(2) img {
  width: 45px;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div:nth-child(3) img {
  width: 30px;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div:nth-child(4) img {
  width: 45px;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div:nth-child(5) img {
  width: 45px;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div:nth-child(6) img {
  width: 70px;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div:nth-child(7) img {
  width: 45px;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div:nth-child(8) img {
  width: 55px;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div:nth-child(9) img {
  width: 40px;
}

.new-dashboard-layout .dashboard-sec-2 .sec-2-wrap div p {
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
}

.new-dashboard-layout .dashboard-sec-3 {
  display: flex;
  margin-top: 20px;
  gap: 30px;
}

.new-dashboard-layout .dashboard-sec-3 .left {
  width: 60%;
  background-color: #fff;
  border-radius: 16px;
  padding: 15px;
}

.new-dashboard-layout .dashboard-sec-3 .right {
  width: 40%;
}

.new-dashboard-layout .dashboard-sec-3 .right .top {}

.new-dashboard-layout .dashboard-sec-3 .right .bottom {
  background: #fff;
  border-radius: 16px;
  padding: 20px 15px;
}

.new-dashboard-layout .dashboard-sec-3 .right .bottom h3 {
  font-weight: 700;
  font-style: normal;
  color: #406dc9;
  text-decoration: none;
  font-size: 24px;
  margin-bottom: 10px;
}

.new-dashboard-layout .dashboard-sec-3 .right .bottom .rase-found-wrap {
  display: flex;
  gap: 20px;
}

.new-dashboard-layout .dashboard-sec-3 .right .bottom .rase-found-wrap div {
  flex: 1;
  background: rgb(241, 240, 241);
  padding: 20px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.new-dashboard-layout .dashboard-sec-3 .right .bottom .rase-found-wrap div a {
  text-align: center;
  text-decoration: none;
}

.new-dashboard-layout .dashboard-sec-3 .right .bottom .rase-found-wrap div:nth-child(1) img {
  width: 60px;
}

.new-dashboard-layout .dashboard-sec-3 .right .bottom .rase-found-wrap div:nth-child(2) img {
  width: 50px;
}

.new-dashboard-layout .dashboard-sec-3 .right .bottom .rase-found-wrap div:nth-child(3) img {
  width: 50px;
}

.new-dashboard-layout .dashboard-sec-3 .right .bottom .rase-found-wrap div p {
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.dashboard-sec-3 .dashboard-info-social-media-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 1vw;
  border: 1px solid #90e3fb;
  margin-bottom: 20px;
  padding: 20px 15px;
}

.dashboard-sec-3 .dashboard-info-social-media-card button {
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  font-size: 20px;
  font-weight: 600;
  padding: 4px 15px;
  border: 1px solid var(--neddie-blue);
  border-radius: 6px;
  color: white;
  background: var(--neddie-blue);
  border-color: var(--neddie-blue);
  margin-top: 5px;
  width: fit-content;
}

.dashboard-sec-3 .dashboard-info-h2 {
  font-weight: 700;
  font-style: normal;
  color: #406dc9;
  text-decoration: none;
  font-size: 24px;
  padding: 0;
}

.dashboard-manage-pay-h2 {
  font-weight: 700;
  font-style: normal;
  color: #4471ca;
  text-decoration: none;
  font-size: 36px;
  padding: 0;
  margin-bottom: .5rem !important;
  margin-top: 0 !important;
}

@media only screen and (max-width: 768px) {
  .dashboard-footer {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    position: static;
    bottom: 0
  }

  .dashboard-copyright {
    height: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0.1vw solid #d9dadb;
    background-color: #f8fafa;
    width: 100%;
  }

  .dashboard-copyright p {
    padding-left: 1vw;
    color: #173269;
    font-size: 0.75vw;
    font-size: 12px;
    font-weight: 600;
  }

  .dashboard-bottom-nav-container {
    height: 2.5vw;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    border-top: 0.1vw solid #d9dadb;
    background-color: #f8fafa;
    padding-right: 0vw;
    justify-content: center !important;
  }

  .dashboard-bottom-nav-container .nav-link {
    font-size: 0.9vw;
    font-size: 12px;
    color: #173269;
    font-weight: 600;
    padding-right: 3vw;
  }
}