.dashboard-info-page-container {
  display: flex;
  flex-direction: row;
  padding: 1vw;
  width: 100%;
  height: 100%;
}
.dashboard-info-left-col {
  padding: 1vw;
  flex-direction: column;
}
.dashboard-info-right-col {
  padding: 1vw;
  flex-direction: column;
 
}


.dashboard-info-growth-trend-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 37vw;
  height: 46vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: 1px solid #90e3fb;
  margin-bottom: 1vw;
  padding: 0.5vw;
}

.dashboard-update-row {
  display: grid;
  grid-template-columns: 2fr;
  align-items: center !important;
  padding: .9vw 0 0 0vw;
  font-size: small;
  padding: 10px 10p;
}

.dashboard-update-boxes-container {
  display: flex;
  flex-direction: column;
  margin-top: .3vw;
  margin-bottom: 1vw;
  width:100%;
  height:100%;
  overflow-y: auto;
}

.dashboard-update-title {
  /* font-size: medium; */
  margin-left: 1vw;
  font-size: 25.33px;
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.dashboard-update-description {
  /* font-size: small; */
  margin-left: 1;
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 22px;
  line-height: 26px;
}

.dashboard-update-link {
  font-size: small;
  margin-left: 1vw;
  color: blue
}


.dashboard-update-background-box {
  border: none;
  width: 100%;
  height: auto;
  border-color: #a4b2ee;
  border-radius: 5px;
  background: rgb(241, 240, 241);
  padding: 15px 5px;
}

.dashboard-update-text-box {
  position: absolute;
  width:34vw;
  height:78px;
  background-color: white;
  margin: 1px;
  padding: 1vw;
  margin-left: 10px;
  
 
}

.dashboard-icon-container {
  height: min-content

}


/* .dashboard-info-card-overlay {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  color: #ffff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 1vw;
  background-color: #43425d;
}
.dashboard-info-card-overlay .dashboard-info-large-card-overlay {
  color: #ffff;
  font-size: 3vw;
  font-weight: 700;
  text-align: center;
}
.dashboard-info-card-overlay .dashboard-info-small-overlay {
  color: #ffff;
  font-size: 2vw;
  font-weight: 700;
  text-align: center;
}
.dashboard-info-growth-trend-card:hover .dashboard-info-card-overlay {
  opacity: 0.7;
} */

.tooltip {
  width: 10vw !important;
}

.dashboard-info-icon-text {
  font-size: medium;
 
}

.dashboard-info-icon {
  color: #4A6CB3;
  margin-left: 5px;
}

.dashBoard-infocard-top-row {
  display: flex;
  flex-direction: row;
  
}
.dashBoard-infocard-bottom-row {
  display: flex;
  flex-direction: row;
  margin-top: 1vw;
  padding-left: 6vw;
}
.dashboard-infocard-label {
  display: flex;
  flex-direction: row;
}
.dashboard-infocard-label > p {
  color: #4d4f5c;
  font-size: 0.5vw;
  font-size: clamp(1px, 0.5vw, 20px);
}
.dashboard-infocard-label-image {
  height: 0.75vw;
  width: 1vw;
}
.dashboard-info-h2 {
  display: inline-block;
  position: relative;
  font-size: 1.5vw;
  font-size: clamp(5px, 1.5vw, 50px);
  font-weight: 700;
  padding: 0.5vw;
  padding-right: 0;
}
.dashboard-info-h2::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 1.5vw;
  left: 5%;
  border-bottom: 0.15vw solid #83bedf;
}
.dashboard-info-h3 {
  display: inline-block;
  position: relative;
  font-size: 1.1vw;
  font-size: clamp(5px, 1.1vw, 50px);
  font-weight: 700;
  padding: 0.5vw;
  padding-right: 0;
}
.dashboard-info-h3::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 1.5vw;
  left: 5%;
  border-bottom: 0.15vw solid #83bedf;
}
.dashboard-info-monthly-expenses-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 37vw;
  height: 22.50vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: 1px solid #90e3fb;
  margin-bottom: 1vw;
  padding: 0.5vw;
}
.dashboard-info-monthly-expenses-card:hover .dashboard-info-card-overlay {
  opacity: 0.7;
}
.dashboard-info-monthly-expenses-left-info {
  display: flex;
  flex-direction: row;
}
.dashboard-info-monthly-image {
  position: relative;
  margin-top: 1vw;
  margin-left: 1.5vw;
  height: 16vw;
  width: 16vw;
}

.dashboard-info-monthly-expenses-right-info {
  display: flex;
  flex-direction: column;
  padding-left: 4vw;
}
.dashboard-info-ellipse-image {
  width: 1vw;
  height: 1vw;
}
.dashboard-info-monthly-category {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5vw;
  padding-left: 2vw;
  height: 1.5vw;
}
.dashboard-info-monthly-category > p {
  color: #c6c6c6;
  font-size: 0.75vw;
  font-size: clamp(3px, 0.75vw, 30px);
}
.dashboard-info-monthly-card-text {
  display: flex;
  flex-direction: column;
  align-self: center;
  /* padding-top: 1vw; */
  padding-bottom: 1vw;
  width: 15vw;
  color: #406dc9;
  font-size: 0.75vw;
  font-size: clamp(3px, 0.75vw, 40px);
  font-weight: 600;
  text-align: center;
}
.dashboard-info-monthly-card-text p {
  font-size: 1vw;
  font-size: clamp(5px, 1vw, 50px);
}
.dashboard-info-monthly-card-text h3 {
  font-size: 1.2vw;
  font-size: clamp(5px, 1.2vw, 50px);
  font-weight: 600;
}
.dashboard-info-monthly-total {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: 2vw;
}
.dashboard-info-monthly-total::before {
  content: '';
  position: absolute;
  top: 0;
  width: 12vw;
  left: 10%;
  border-top: 0.15vw solid #c6c6c6;
}

.dashboard-info-monthly-total > p {
  padding-top: 0.25vw;
  color: #c6c6c6;
  font-size: 0.75vw;
  font-size: clamp(3px, 0.75vw, 30px);
}

.dashboard-quick-link-box-container {
  /* display:inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: .5vw; */
  
}

.dashboard-quick-link-box{
  margin-right: 1vw;
  margin-top: 1vw;
  width: 100px;
  height: 78px;
  border-radius: 10px;
  background-color: #EEEEEE;
}

.dashboard-link-box-text {
  display: flex;
  justify-content: center;
  font-size: medium;
}

.dashboard-campaign-box-text {
  display: grid;
  justify-content: center;
  font-size: medium;
  margin-bottom: 5px;
}

.dashboard-info-quick-overview-card {
  position: relative;
  width: 37vw;
  height: 22.50vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: 1px solid #90e3fb;
  margin-bottom: 1vw;
  padding: 0.5vw;
}

.dashboard-link-icon {
  display: flex;
  justify-content: center;
  height:30px;
  width: 30px;
  margin-top: .2vw;
}

.dashboard-link-box {
  display: flex;
  justify-content: center;

}

.dashboard-campaign-title-box {
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  font-weight:bold;
 

}

.dashboard-share-icon .share-symbol-blue {
  float: right;
  margin-right: 1vw;
  height: 15px !important;
  width: 15px !important;

}

.dashboard-quick-link-box .share-symbol-blue {
  float: right;
  margin-right: 1vw;
  height: 15px ;
  width: 15px ;
}

.dashboard-active-fundaraiser-body {
  margin-top: 2vw;
  justify-content: left;
  font-size: medium;
}

.dashboard-progress-bar .progress-bar {
  display: flex !important;
  justify-content: left;
  background-color: rgb(82, 113, 255) !important;
}

.dashboard-goal-text {
  display: flex;
  float: right !important;
}

.dashboard-info-quick-overview-card:hover .dashboard-info-card-overlay {
  opacity: 0.7;
}
.dashboard-info-quickoverview-icon-container {
  display: flex;
  align-self: center;
}
.dashboard-info-quickoverview-icon {
  padding-top: 0.5vw;
  height: 3vw;
  width: 3vw;
}
.dashboard-info-active-campaigns-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 31vw;
  height: 9.25vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: 1px solid #90e3fb;
  margin-bottom: 1vw;
  margin-left: 1vw;
  padding: 0.5vw;
}
.dashboard-info-active-campaigns-card:hover .dashboard-info-card-overlay {
  opacity: 0.7;
}
.dashboard-info-card-text {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-top: 0.5vw;
  width: 15vw;
  color: #406dc9;
  font-size: 0.75vw;
  font-size: clamp(3px, 0.75vw, 40px);
  font-weight: 600;
  text-align: center;
}
.dashboard-info-card-text h3 {
  font-size: 1.2vw;
  font-size: clamp(3px, 1.2vw, 50px);
  font-weight: 600;
}
.dashboard-info-social-media-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 37vw;
  height: 22.50vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: 1px solid #90e3fb;
  margin-bottom: 1vw;
  padding: 0.5vw;
}
.dashboard-info-social-media-card:hover .dashboard-info-card-overlay {
  opacity: 0.7;
}
.dashboard-info-social-media-activity {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.dashboard-info-social-media-image {
  margin-top: 1.25vw;
  height: 3vw;
  width: 15vw;
}
.dashboard-info-info-icon-container {
  position: relative;
  z-index: 100;
}
.dashboard-info-info-icon {
  width: 1.25vw;
  height: 1.25vw;
  z-index: 100;
}
.dashboard-info-info-icon-container .tooltiptext {
  visibility: hidden;
  width: 12vw;
  height: 10vw;
  background-color: #ffff;
  color: #406dc9;
  text-align: center;
  border-radius: 1vw;
  padding: 0.5vw;
  border: 0.1vw solid #c6c6c6;
  bottom: -3vw;
  position: absolute;
  z-index: 100;
}
.dashboard-info-info-icon-container
  .tooltiptext
  .dashboard-info-tooltiptext-h3 {
  font-size: 1vw;
  font-size: clamp(3px, 1vw, 50px);
  padding-bottom: 0.25vw;
}
.dashboard-info-info-icon-container .tooltiptext .dashboard-info-tooltiptext-p {
  font-size: 0.75vw;
  font-size: clamp(3px, 0.75vw, 50px);
}
.dashboard-info-info-icon-container:hover .tooltiptext {
  visibility: visible;
}

.dashboard-info-arrow {
  border: solid black;
  border-width: 0 0.1vw 0.1vw 0;
  display: inline-block;
  padding: 0.1vw;
  margin-left: 0.5vw;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.dashboard-info-btn {
  margin-top: 0.75vw;
  margin-left: 15vw;
  height: 1.3vw;
  width: 6vw;
  border: 0.1vw solid #dededf;
  background-color: transparent;
  font-size: 0.5vw;
  font-weight: 700;
}
.dashboard-info-graph-container {
  padding-top: 2vw;
  padding-left: 0.5vw;
  position: relative;
}
.dashboard-info-graph {
  width: 55vw;
  height: 14vw;
}
.dashboard-info-overlay-text {
  position: absolute;
  top: 35%;
  left: 50%;
  color: #406dc9;
  font-size: 0.75vw;
  font-size: clamp(3px, 0.75vw, 40px);
  font-weight: 600;
  text-align: center;
  transform: translate(-50%, -50%);
}
.dashboard-info-overlay-text p {
  font-size: 1vw;
  font-size: clamp(5px, 1vw, 50px);
}
.dashboard-info-overlay-text h3 {
  font-size: 1.2vw;
  font-size: clamp(5px, 1.2vw, 50px);
  font-weight: 600;
}

.whats-new-popups .modal-content{
  border-radius: 30px;
}
.whats-new-popups .manage-marketplace-info-container{
  width: 100%;
  height: auto !important;
  background-color: #ffff;
  border-radius: 1vw;
  padding: 3vw;
  margin-top: 1.5vw;
  font-weight: 600;
}

.whats-new-popups .modal-dialog {
    max-width: 90vw
}

.whats-new-popups .modal-content .manage-wish-wrap{
  padding: 30px;
  font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 27px;
  position: relative;
}

.whats-new-popups .dashboard-info-h2 {
  font-size: 31px;
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0;
  margin: 0px 0px 20px 0px;
}

.whats-new-popups .manage-marketplace-addlist-form label {
font-size: 26px;
padding-top: 1vw;
font-weight: 500;
  font-style: normal;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.whats-new-popups  .manage-marketplace-addlist-form input {
border: 1px solid #000;
border-radius: 0.25vw;
width: 80%;
height: auto;
color: #6a6969;
background-color: rgb(241, 240, 241);
padding-left: 0.7vw 0.5vw;
font-weight: 500;
  font-style: normal;
  color: #000;
  text-decoration: none;
  font-size: 26px;
  margin-bottom: 20px;
}


.whats-new-popups  .manage-marketplace-addlist-form input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
color: #B2AFB2;
font-size: 26px;
}

.whats-new-popups  .manage-marketplace-addlist-form input:-moz-placeholder,
textarea:-moz-placeholder {
color: #B2AFB2;
font-size: 26px;
}

.whats-new-popups .neddie-button{
transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
font-size: 26px;
font-weight: 600;
padding: 4px 36px;
border: 1px solid var(--neddie-blue);
border-radius: 50vw;
color: white;
background: var(--neddie-blue);
border-color: var(--neddie-blue);
margin-top: 5px;
width: fit-content;
}

.whats-new-popups .cancle-popup{
position: absolute;
top: 17px;
right: 25px;
font-size: 34px;
padding: 10px;
line-height: 16px;
cursor: pointer;
}

.whats-new-popups .share-symbol-blue{
position: absolute;
width: 100% !important;
height: 100% !important;
top: 0px;
opacity: 0;
cursor: pointer;
}

.whats-new-popups .dashboard-quick-link-box-container  .one .dashboard-link-icon{
  width: 50px !important;
  height: auto !important;
}
.whats-new-popups .dashboard-quick-link-box-container  .two .dashboard-link-icon{
  width: 50px !important;
  height: auto !important;
}
.whats-new-popups .dashboard-quick-link-box-container  .three,
.whats-new-popups .dashboard-quick-link-box-container  .one,
.whats-new-popups .dashboard-quick-link-box-container  .two,
.whats-new-popups .dashboard-quick-link-box-container  .four,
.whats-new-popups .dashboard-quick-link-box-container  .five{
  width: auto !important;
  height: auto !important;
  flex: 1 !important;
  margin: 0;
}
.whats-new-popups .dashboard-quick-link-box-container  .three .dashboard-link-icon{
  width: 40px !important;
  height: auto !important;
}
.whats-new-popups .dashboard-quick-link-box-container  .four .dashboard-link-icon{
  width: 50px !important;
  height: auto !important;
}
.whats-new-popups .dashboard-quick-link-box-container  .five .dashboard-link-icon{
  width: 50px !important;
  height: auto !important;
}

@media only screen and (max-width: 768px) {

  .tooltip {
    width: 50vw !important;
  }
  .whats-new-popups .dashboard-info-h2 {
    font-size: 24px;
  }
  
  .dashboard-update-title {
    font-size: 20px;
  }
  
  .dashboard-update-description {
    font-size: 16px;
    line-height: 20px;
  }
  
  .dashboard-update-title {
    line-height: 20px;
    margin-bottom: 10px;
  }
  
  .dashboard-update-boxes-container{
    height: 330px;
  }

  .whats-new-popups .cancle-popup {
    position: absolute;
    top: 7px;
    right: 9px;
}

.whats-new-popups .modal-dialog {
  max-width: 100vw;
}

.whats-new-popups .modal-content {
border-radius: 16px;
}
}