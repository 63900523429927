/* Sharing BUtton Styling */

.sharing-options-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19);
}

.close-modal-x {
  font-size: x-large;
  font-weight: 800;
  background-color: transparent;
  border: none;
  color: #bcbfc2;
  position: absolute;
  left: 92%;
  top: 2.5%;
}

.sharing-options-modal-title {
  text-align: center;
  padding: 25px 0 5px 0;
}

.sharing-options-options-container {
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
}

.sharing-options-options-container-child {
  width: 50%;
}

.sharing-Options-btn {
  border: 1.5px solid rgb(198, 198, 198) !important;
  padding: 0.5rem 1rem !important;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.options-btn-container {
  padding: 10px;
  width: 100%;
}

.close-button-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.share-symbol-img img {
  height: 30px !important;
}

.share-symbol-blue {
  height: 30px;
  color: var(--neddie-blue);
}

.share-symbol-white {
  height: 30px;
  color: #fff;
}

.share-symbol-blue:hover,
.share-symbol-white:hover {
  cursor: pointer;
}
