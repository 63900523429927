.npp-drop-zone-text {
  color: #bcbfc2;
}

.npp-drop-zone-container {
  background-size: cover;
  background-position: center;
  width: 15vh;
  height: 10vh;
  border-radius: 20px;
  border: 1px dashed #aaa0a0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;
}

.npp-image-dropper-cropper {
  width: 15vh;
}

.npp-modal-cropper-title {
  font-size: 2.5vmin;
  font-weight: 500;
  margin: 4vh 0 auto 0;
}

.npp-drop-zone-container p {
  font-size: 2vmin;
}

.npp-modal-cropper {
  margin-bottom: 3vh;
}

.npp-modal-cropper-wrapper {
  position: fixed;
  border-radius: 20px;
  top: 50%;
  left: 50%;
  width: 60vw;
  height: 80%;
  margin: -35vh 0 0 -25vw;
  z-index: 150;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2vh 2vw 2vh 2vw;
}

.npp-dropper-cropper-buttons {
  margin-top: auto;
  display: flex;
  padding: 20px;
}

.npp-dropper-cropper-edit-dropdown {
  position: absolute;
  top: 5px;
  right: 5px;
}

.npp-dropper-cropper-edit-dropdown>button {
  background-color: var(--neddie-blue);
  border-radius: 50%;
  font-size: 1.5rem;
  line-height: 0;
  padding: 5px;
}

.npp-dropper-cropper-edit-dropdown>button::after {
  display: none;
}

.save-button {
  margin-right: 20px;
}

.ReactCrop>div {
  height: 100%;
  width: 100%;
}

.ReactCrop>div>img {
  max-height: 100%;
  height: 100%;
  width: 100%;
}