* {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

html,
body {
  touch-action: pan-x pan-y;
  /* overflow-x: hidden; */
  /* Allow scrolling but block zoom-related gestures */
}

.text-logo {
  color: #0077de;
}

.text-ocean {
  color: #406dc9;
}

.text-dark-blue {
  color: #173269;
}

.backgroundColor-ocean {
  background-color: var(--neddie-blue);
}

.backgroundColor-dark-blue {
  background-color: var(--neddie-darkblue)
}

.text-gray {
  color: #807E7E;
}

.text-align-c {
  text-align: center;
}

.bg-gray {
  color: #807E7E;
}

.button {
  background-color: #406dc9;
  border-color: #406dc9;
}

.card-shadow {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19);
}

.new-fundraiser-card {
  margin: 5px;
  background-color: #fff;
  border-radius: 6px;
  /* width: 100%; */
  border: 1px solid #e3e0e3;
}

.new-fundraiser-card .new-fundraiser-card-wrap {}

.new-fundraiser-card .new-fundraiser-card-wrap .f-img {
  position: relative;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-img img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 12px 12px 0px 0px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .d-c img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px 12px 0px 0px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-title-logo {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: rgb(248, 250, 255);
  padding: 5px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-title-logo .logo {}

.new-fundraiser-card .new-fundraiser-card-wrap .f-title-logo .logo img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-title-logo .title {}

.new-fundraiser-card .new-fundraiser-card-wrap .f-title-logo .title a {}

.new-fundraiser-card .new-fundraiser-card-wrap .f-title-logo .title a h4 {
  font-size: 12px;
  color: #5271ff;
  min-height: 34px;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-body {
  padding: 10px 10px 15px 10px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-location {
  display: flex;
  gap: 0px;
  align-items: center;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-location svg {
  color: gray;
  font-size: 20px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-location p {
  font-size: 12px;
  color: gray;
  font-weight: 500;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-desc {
  padding: 10px 0px 10px 0px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-desc p {
  font-size: 14px;
  color: #000;
  min-height: 80px;
  font-weight: 600;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-desc p a {
  color: #03367b;
  font-weight: 600;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-amount-raised {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 5px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-amount-raised .raised {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-amount-raised .raised span {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-amount-raised .goal {
  font-size: 14px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-link {
  text-align: center;
  padding: 20px 0px 5px 0px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-link a {
  border-radius: 25px;
  padding: 8px 40px;
  border: 2px solid #5271ff;
  background-color: #5271ff;
  font-size: 14px;
  color: #ffff;
  font-weight: 600;
  text-decoration: none;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-share-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 25px;
  padding: 0px 10px;
  border: 2px solid #5271ff;
  background-color: #5271ff;
  font-size: 14px;
  color: #ffff;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 2px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-share-btn svg {
  font-size: 22px;
}

@media (max-width: 500px) {

  .new-fundraiser-card .new-fundraiser-card-wrap .f-share-btn {
    right: 5px;
    top: 5px;
    border-radius: 4px;
    padding: 2px 5px;
    border: 1px solid #5271ff;
    font-size: 10px;
    grid-gap: 2px;
    gap: 2px;
  }
  
  .new-fundraiser-card .new-fundraiser-card-wrap .f-share-btn svg {
    font-size: 12px;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-link a {
    border-radius: 4px;
    padding: 5px 20px;
    border: 1px solid #5271ff;
    background-color: #5271ff;
    font-size: 10px;
    color: #ffff;
    font-weight: 600;
    text-decoration: none;
  }


  .new-fundraiser-card {
    margin: 2px;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-title-logo .logo img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-title-logo .title a h4 {
    font-size: 10px;
    color: #5271ff;
    min-height: 24px;
    display: flex;
    align-items: center;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-location p {
    font-size: 10px;
    min-height: auto;
    display: flex;
    align-items: center;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-location svg {
    font-size: 14px;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-location {
    gap: 2px;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-desc p {
    font-size: 10px !important;
    min-height: 58px;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-amount-raised .raised {
    font-size: 10px !important;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-amount-raised .goal {
    font-size: 8px !important;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-amount-raised .raised span {
    font-size: 8px;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-title-logo {
    gap: 5px;
    padding: 5px 5px;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-body {
    padding: 7px 7px 12px 7px;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-desc {
    padding: 5px 0px 5px 0px;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-desc {
    padding: 5px 0px 5px 0px;
  }

  .new-fundraiser-card .new-fundraiser-card-wrap .f-img img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 6px 6px 0px 0px;
}

.new-fundraiser-card .new-fundraiser-card-wrap .f-desc p a{
  font-size: 10px;
}
}